import { ActivatedRoute, Router } from '@angular/router';
import { BusinessUnitDto } from '../../../models/bussinessunits/bussinessunits.model';
import { BussinessUnitService } from '../../../services/bussinessunit.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GraphResponseType } from 'src/app/core/models/graph.models';
import { GraphService } from 'src/app/core/services/graph.service';
import { Group } from '../../../models/group/group.model';
import { GroupService } from '../../../services/group.service';
import { Role } from '../../../models/role/role.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { RoleService } from '../../../services/role.service';
import { SARUsers } from '../../../models/sarusers/sarusers.model';
import { SARUsersConstant } from 'src/app/core/constants/sar-users.constants';
import { SarUsersService } from '../../../services/sar-users.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { UserDetailDto } from 'src/app/core/models/user-detail.model';

@Component({
  selector: 'app-manage-sar-users',
  templateUrl: './manage-sar-users.component.html',
  styleUrls: ['./manage-sar-users.component.scss']
})
export class ManageSarUsersComponent implements OnInit {
  public id: number = 0;
  public isCreate: boolean = false;
  public name: string = '';
  public isActive: boolean = true;
  public objectId: string = '';
  public username: string = '';
  public businessUnitname: string;
  public businessUnitsList: BusinessUnitDto[] = [];
  public groupList: Group[] = [];
  public roleList: Role[] = [];
  public headerText: string = "Create New SAR User"
  public businessUnitId: number = 0;
  public provisionId: string = '';
  public emailId: string = '';
  public externalId: string = '';
  public mask: string = 'LLLL';

  public InputObjectId:string = '';
  public InputEmail: string = '';
  public emailIdError: string = SARUsersConstant.emailError;
  public emailNotFoundError: string = SARUsersConstant.emailNotFoundError;
  public emailAlreadyExistError: string = SARUsersConstant.emailAlreadyExistError;
  public invalidEmailError: string = SARUsersConstant.invalidEmailError;

  public objectIdNotFoundError: string = SARUsersConstant.objectIdNotFoundError;

  hasError: boolean = false;
  //hasCAIError: boolean = false;
  hasObjectIdError: boolean = false; /* Added for 7136137: CAI Deprecation */
  hasUsernameError: boolean = false;
  hasEmailError: boolean = false;
  hasBusinessUnitError: boolean = false;
  hasRoleError: boolean = false;
  hasGroupError: boolean = false;

  sarUserForm = new FormGroup({
    objectId: new FormControl('', Validators.required), /* Added for 7136137: CAI Deprecation */
    email: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    businessUnitname: new FormControl('', Validators.required),
    roleDtos: new FormControl(<Role[] | []>([]), Validators.required),
    groupDtos: new FormControl(<Group[] | []>([]), Validators.required),
    isActive: new FormControl(true),
  });

  selectedBusinessUnit: BusinessUnitDto = new BusinessUnitDto();
  selectedGroups: Group[] = [new Group()];
  groupTypeName: string | undefined;
  roleDtos: Role[] = [new Role];
  groupDtos: Group[] = [new Group];
  roleIds: number[] = [];
  groupIds: number[] = [];

  constructor(public router: Router,
              private route: ActivatedRoute,
              private sarUserService: SarUsersService,
              private bussinessUnitService: BussinessUnitService,
              private roleService: RoleService,
              private groupService: GroupService,
              private graphService: GraphService,
              public commonService: CommonService)
  {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;

    if (this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.cancel();
    }

    this.id = stateData['id'];
    this.isCreate = stateData['isCreate'];
    this.name = stateData['name'];
    this.objectId = stateData['objectId']; /* Added for 7136137: CAI Deprecation */
    this.username = stateData['username'];
    this.isActive = stateData['isActive'];
    this.roleDtos = stateData['roleDtos'];
    this.groupDtos = stateData['groupDtos'];
    this.businessUnitname = stateData['businessUnitname'];
    this.businessUnitId = stateData['businessUnitId'];
    this.groupTypeName = stateData['groupTypeName'];
    this.emailId = stateData['email'];
    this.provisionId = stateData['provisionId'];
    this.externalId = stateData['externalId'];
    this.InputEmail = this.emailId;
    this.InputObjectId = this.objectId;

    if (!this.isCreate) {
      this.headerText = stateData['name']
    }

  }

  ngOnInit() {
    if (this.commonService.getUserRoleName() !== RoleConstant.ESAR_Admin) {
      this.router.navigate(['../../sar'], { relativeTo: this.route });
      return false;
    }
    this.getBusinessUnitList();
    this.getGroupList();
    this.getRoleList();

    if (!this.isCreate) {
      this.groupIds = [];
      this.groupDtos.forEach(el => this.groupIds.push(el.id));
      this.roleIds = [];
      this.roleDtos.forEach(el => this.roleIds.push(el.id));
      this.InputEmail = this.emailId;
      this.InputObjectId = this.objectId;

      this.sarUserForm.setValue({
        email: this.emailId,
        username: this.username,
        businessUnitname: this.businessUnitname,
        objectId: this.objectId, /* Added for 7136137: CAI Deprecation */
        roleDtos: this.roleDtos,
        groupDtos: this.groupDtos,
        isActive: this.isActive
      });

      //Call graph api to fetch username if blank
      if (!this.emailId || this.emailAlreadyExistError == null) {
        this.handleBlur();
      }

    }
  }

  getBusinessUnitList() {
    this.bussinessUnitService.getBUAll().subscribe((outputDtata: BusinessUnitDto[]) => {
      this.businessUnitsList = outputDtata;
    });
  }

  getGroupList() {
    this.groupService.getGroup().subscribe((outputDtata: Group[]) => {
      this.groupList = outputDtata;
    });
  }

  getRoleList() {
    this.roleService.getRole().subscribe((outputDtata: Role[]) => {
      this.roleList = outputDtata;
    });
  }

  clearError() {
    this.hasObjectIdError = false; /* Added for 7136137: CAI Deprecation */
    this.hasEmailError = false;
    this.hasUsernameError = false;
    this.hasBusinessUnitError = false;
    this.hasRoleError = false;
    this.hasGroupError = false;
    this.hasError = false;
  }

  selectBusinessUnit(val: BusinessUnitDto) {
    this.selectedBusinessUnit = val;
    const buName = this.selectedBusinessUnit.name;
    const buId = this.selectedBusinessUnit.id;
    this.businessUnitname = <string>buName;
    this.businessUnitId = <number>buId;
  }

  selectRole(val: Role[]) {
    this.roleDtos = val;
    this.roleIds = [];
    this.roleDtos.forEach(el => this.roleIds.push(el.id))
  }

  selectGroup(val: Group[]) {
    this.groupDtos = val;
    this.groupIds = [];
    this.groupDtos.forEach(el => this.groupIds.push(el.id))
  }

  save() {
    this.clearError();

    const user = new SARUsers();
    user.isActive = <boolean>this.sarUserForm.get('isActive')?.value;
    user.createdBy = 'rrou'
    user.modifiedBy = 'rrou'
    user.name = this.username;
    user.userName = this.username;
    user.id = <number>this.id;
    user.businessUnitname = "";
    user.email = this.InputEmail;
    user.provisionId = this.provisionId;
    user.externalId = this.externalId;
    user.businessUnitId = this.businessUnitId;
    user.objectId = (this.InputObjectId === null || this.InputObjectId === '') ? this.externalId : this.InputObjectId; /* Added for 7136137: CAI Deprecation */
    user.groupDtos = [];
    user.roleDtos = [];
    user.roleIds = this.roleIds;
    user.groupIds = this.groupIds;

    if (this.sarUserForm.status == 'INVALID') {

      if (this.sarUserForm.get('objectId')?.invalid) {
        this.hasObjectIdError = true;
        this.hasError = true;
      }

      if (this.sarUserForm.get('email')?.invalid) {
        this.sarUserForm.get('email')?.markAsTouched();
        this.hasEmailError = true
        this.hasError = true;
        this.setEmailErrors(true, SARUsersConstant.emailError);
      }

      if (this.sarUserForm.get('businessUnitname')?.invalid) {
        this.sarUserForm.get('businessUnitname')?.markAsTouched();
        this.hasBusinessUnitError = true;
        this.hasError = true;
      }

      if (this.isCreate == false && this.sarUserForm.get('username')?.invalid) {
        this.sarUserForm.get('username')?.markAsTouched();
        this.hasUsernameError = true;
        this.hasError = true;
      }

      if (this.sarUserForm.get('roleDtos')?.invalid) {
        this.sarUserForm.get('roleDtos')?.markAsTouched();
        this.hasRoleError = true;
        this.hasError = true;
      }

      if (this.sarUserForm.get('groupDtos')?.invalid) {
        this.sarUserForm.get('groupDtos')?.markAsTouched()
        this.hasGroupError = true;
        this.hasError = true;
      }

      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
    }

    else {
      if (!this.isCreate) {
        this.sarUserService.updateSARUsers(user).subscribe((response: SARUsers) => {
          this.router.navigate([SARUsersConstant.SARUserRoute], { relativeTo: this.route.parent });
          this.commonService.showNotificationMessage('User has been updated successfully', 'success', 'right');
        });
      }
      else {
        this.sarUserService.saveSARUsers(user).subscribe({
          next: (response: SARUsers) => {
            this.router.navigate([SARUsersConstant.SARUserRoute], { relativeTo: this.route.parent });
            this.commonService.showNotificationMessage('User has been added successfully', 'success', 'right');
          },
          error: err => {
            this.commonService.showNotificationMessage(err.message, 'error', 'center');
          }
        })
      }
    }

  }

  cancel() {
    this.router.navigate(['./SARUsers'], { relativeTo: this.route.parent });
  }

  handleBlur(): void {
    const useremailId = <string>this.sarUserForm.get('email')?.value;

    if (this.isCreate && this.isValidateEmail(useremailId)) {
      this.hasActiveUser(useremailId);      
    }
    else {
        this.handleEmailValidation();
    }

  }

  emailValueChange(val: string) {
    if (this.isCreate && (this.InputEmail.toLowerCase() === val.toLowerCase())) {
      this.handleEmailValidation();
    }
  }

  handleEmailValidation() {
    const emailId = <string>this.sarUserForm.get('email')?.value;

    if (this.sarUserForm.get('email')?.touched && emailId.length == 0) {
      this.setEmailErrors(true, SARUsersConstant.emailError);
      return;
    }

    if (this.sarUserForm.get('email')?.touched && !this.isValidateEmail(emailId)) {
      this.setEmailErrors(true, SARUsersConstant.invalidEmailError);
      return;
    }

    this.setEmailErrors(false);
    this.sarUserForm.get('email')?.setErrors(null);
  }

  setEmailErrors(val: boolean, error?: string) {
    this.hasEmailError = val;
    this.hasError = val;

    if (error) {
      this.emailIdError = error;
      this.sarUserForm.controls.email.setErrors({ 'incorrect': val });
    }
  }

  hasActiveUser(inputUserEmailId: string) {
    this.sarUserService.getUserDetails(inputUserEmailId).subscribe((response: UserDetailDto) => {
      if (response != null && response.email.length > 0) {
        this.setEmailErrors(true, SARUsersConstant.emailAlreadyExistError);
      }
      else{
        this.graphService.getAdUserNameByEmail(inputUserEmailId).subscribe((response: GraphResponseType) => {
          if (response && response.value && response.value.length === 0) {
            this.setEmailErrors(true, SARUsersConstant.emailNotFoundError);
            return;
          }
          
          this.username = response.value[0].givenName + ' ' + response.value[0].surname;
          this.provisionId = <string>response.value[0].extension_39c7d3e68666465dab296ee0fc538118_cvx_ProvisioningID;
          this.emailId = <string>response.value[0].userPrincipalName;
          this.externalId = <string>response.value[0].id;
          this.objectId = <string>response.value[0].id; /* Added for 7136137: CAI Deprecation */
          this.InputObjectId = this.objectId;
          this.sarUserForm.patchValue({ username: this.username });
          this.sarUserForm.get('email')?.setErrors(null);
          this.setEmailErrors(false);
        })

      }

    });
  }

  isValidateEmail(inputmailId: string) {
    return inputmailId.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

}