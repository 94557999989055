<section>
    <div class="mt-20 text-align-right">
        <button kendoButton class="upload-button"
            *ngIf="isUploadExcelVisible && requestAddNewActionItemDetails!.items.length > 0" (click)="import()"> Upload
            Excel </button>
        <button kendoButton class="upload-button ml-10" [disabled]="requestAddNewActionItemDetails!.items.length === 0"
            (click)="exportToExcel()"> Download Excel </button>
        <button kendoButton class="primary-bulk-button  ml-5 f-size-150 " *ngIf="currentActionName !== 'summary'"
            [disabled]="isBulk || requestAddNewActionItemDetails!.items.length === 0" (click)="openPopup()">
            Bulk Action
        </button>
    </div>
</section>
<h3>Item List</h3>
<!-- Grid -->
<kendo-grid kendoGridSelectBy="lineNumber" [(selectedKeys)]="selectedLines"
    [data]="requestAddNewActionItemDetails!.items" (selectionChange)="clickCheckBox($event)" [selectable]="true"
    kendoGridSelectBy [(selectedKeys)]="mySelection" #item style="height:auto">
    <kendo-grid-checkbox-column [width]="40" [showSelectAll]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <input *ngIf="currentActionName !== 'summary' &&
            (dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed')"
                type="checkbox" id="checkbox-{{rowIndex}}" [kendoGridSelectionCheckbox]="rowIndex" />
        </ng-template>
        <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
            <input kendoGridSelectAllCheckbox *ngIf="currentActionName !== 'summary'" type="checkbox"
                id="headerCheckbox" />
        </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column title="Line No." [width]="100">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
            {{dataItem.lineNumber}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    <kendo-grid-column field="manufactureName" title="Manufacturer"></kendo-grid-column>
    <kendo-grid-column field="manufacturePartNumber" title="Manufacturer Part Number"></kendo-grid-column>
    <kendo-grid-column field="unitOfMeasureName" title="Unit of Measure"></kendo-grid-column>
    <kendo-grid-column field="materialTypeName" title="Material Type"></kendo-grid-column>
    <kendo-grid-column field="materialGroupCodeName" title="Material Group Code"></kendo-grid-column>
    <kendo-grid-column *ngIf="currentActionName === 'mmd-action'" title="Material Number">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-textarea class="mt-5" [rows]="4" [disabled]="dataItem.status==='Completed' && !dataItem.isRowEdit"
                [(ngModel)]="dataItem.materialNumber" (ngModelChange)="matNumberChange(dataItem)" [cols]="100"
                resizable="both"></kendo-textarea>
            <br />
            <span style="color: red;" *ngIf="dataItem.isMatValid === false"> Please enter numbers only</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="currentActionName === 'mmd-action'" title="Short Description">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-textarea class="mt-5" [rows]="4" [disabled]="dataItem.status==='Completed' && !dataItem.isRowEdit"
                [(ngModel)]="dataItem.shortDescription" [cols]="100" resizable="both"></kendo-textarea>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="currentActionName === 'mmd-action'" title="Enriched Description ">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-textarea class="mt-5" [rows]="4" [disabled]="dataItem.status==='Completed' && !dataItem.isRowEdit"
                [(ngModel)]="dataItem.enrichedDescription" [cols]="100" resizable="both"></kendo-textarea>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Action/Status" [width]="370">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div *ngIf="currentActionName !== 'summary' &&
             (dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed')">
                <span style="display: none;">{{assignEmpty(dataItem)}}</span>
                <kendo-textarea placeholder="Add Comment" class="mt-5" [rows]="4" [(ngModel)]="dataItem.comment"
                    [cols]="100" resizable="both"></kendo-textarea>
                <br />
                <span *ngIf="dataItem.isCommentError" style="color: red;">Please enter Comment</span>
                <br />
                <button kendoButton class="border-button ml-5 f-size-150 cancelButton btn-wth"
                    (click)="cancel(dataItem)">
                    Cancel
                </button>
                <button kendoButton class="border-button ml-5 f-size-150 consultButton" (click)="consult(dataItem)">
                    Consult
                </button>
                <kendo-dialog title="Consult" *ngIf="consultClicked" (close)="close()" [height]="300" [width]="500">
                    <div class="consult-item-container">
                        <div>
                            <kendo-label text="Assign to group"><span style="color:red"> *</span></kendo-label>
                            <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedGroup" [data]="groups"
                                [defaultItem]="groupSelectPlaceHolder" textField="name" valueField="id"
                                (selectionChange)="groupSelectionChange($event)">
                            </kendo-dropdownlist>
                        </div>
                        <div>
                            <kendo-label text="Assign to sub group / user"></kendo-label>
                            <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedUserGroup" [data]="groupUsers"
                                [defaultItem]="userGroupSelectPlaceHolder" textField="name" valueField="id"
                                (selectionChange)="userGroupSelectionChange($event)"
                                [disabled]="userGroupSelectDisabled">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <br>
                    <br>
                    <kendo-label text="QA Codes"></kendo-label>
                    <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedQaCode" [data]="qaCodes"
                        [defaultItem]="qaCodeSelectPlaceHolder" textField="name" valueField="id"
                        (selectionChange)="qaCodeSelectionChange($event)">
                    </kendo-dropdownlist>
                    <kendo-dialog-actions>
                        <button kendoButton (click)="closeDialog()">Cancel</button>
                        <button kendoButton (click)="consultActionClick()" class="primary-button"
                            [disabled]="consultBtnDisabled">Consult</button>
                    </kendo-dialog-actions>
                </kendo-dialog>
                <kendo-dialog class="error-dialog" title="Error" *ngIf="consultErrorDialog"
                    (close)="closeConsultError()" [minWidth]="250" [width]="100">
                    <p style="text-align: center;">
                        Please select the assigned user group. In case you want assign it only to the group, select sub-group
                    </p>
                </kendo-dialog>
                <button kendoButton class="primary-button ml-5 f-size-150 rejectButton btn-wth"
                    (click)="reject(dataItem)">
                    Reject
                </button>
                <button kendoButton class="primary-button ml-5 f-size-150 successButton btn-wth"
                    [disabled]="currentActionName === 'mmd-action' && fieldValidation(dataItem)"
                    (click)="complete(dataItem,false)">
                    {{getSAButtonText()}}
                </button>
            </div>
            <div
                *ngIf="dataItem.status ==='Cancelled' || dataItem.status ==='Rejected' || dataItem.status ==='Completed'
            || (currentActionName === 'summary' && (dataItem.status ==='Pending Stocking Approval' || dataItem.status ==='Pending MMD') )">

                <table *ngIf="dataItem.status==='Completed'">
                    <tr>
                        <td class="emptyBorder">
                            <div *ngIf="dataItem.status==='Completed'" class="d-flex a-center status-box"
                                [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
                            dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' :'' ">
                                <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                                    {{ dataItem?.status }}
                                </p>
                            </div>
                        </td>
                        <td class="emptyBorder" *ngIf="!dataItem.isRowEdit && currentActionName !== 'summary'">
                            <a class="pencil-style" (click)="editRow(dataItem, true,rowIndex)"><i
                                    class="fa fa-pencil font-size-base" aria-hidden="true"></i></a>
                        </td>
                    </tr>
                    <tr *ngIf="dataItem.isRowEdit">
                        <td class="emptyBorder d-flex">
                            <button kendoButton class="border-button mr-5 w-160"
                                (click)="editRow(dataItem, false,rowIndex)">
                                Cancel Editing
                            </button>
                            <button kendoButton class="primary-button ml-5 w-160"
                                [disabled]="currentActionName === 'mmd-action' && fieldValidation(dataItem)"
                                (click)="complete(dataItem,true)">
                                Update
                            </button>

                        </td>
                        <td class="emptyBorder">

                        </td>
                    </tr>
                </table>
                <div *ngIf="dataItem.status==='Rejected'" class="d-flex a-center status-box"
                    [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
                        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' : '' ">
                    <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                        {{ dataItem?.status }}
                    </p>
                </div>
                <div *ngIf="dataItem.status==='Cancelled'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
                dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' :'' ">
                    <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                        {{ dataItem?.status }}
                    </p>
                </div>
                <div *ngIf="dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed'"
                    class="d-flex a-center status-box">
                    <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                        {{ dataItem?.status }}
                    </p>
                </div>

            </div>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridDetailTemplate let-dataItem>
        <section>
            <kendo-grid style="height:auto" scrollable="none" [data]="[dataItem.addNewItemActionDetails]">
                <kendo-grid-column field="grossWeight" title="Gross Weight(LB)">
                </kendo-grid-column>
                <kendo-grid-column field="dimensions" title="Size/ Dimensions"></kendo-grid-column>
                <kendo-grid-column field="materialStatusName" title="Plant Sp. Matl Status"></kendo-grid-column>
                <kendo-grid-column field="storageConditioName" title="Storage Condition"></kendo-grid-column>
                <kendo-grid-column field="unitPrice" title="Unit Price"></kendo-grid-column>
                <kendo-grid-column field="leadTime" title="Lead Time (Days)"></kendo-grid-column>
                <kendo-grid-column field="qaCodes" title="QA Code">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <ng-container *ngFor="let qaCode of dataItem.qaCodes">
                            {{qaCode.qaCode}} <span *ngIf="( dataItem.qaCodes.length - 1 ) > rowIndex">,</span>
                        </ng-container>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="supplierPurchasing" title="Supplier (Purchasing)"></kendo-grid-column>
                <kendo-grid-column field="supplierPartNumber" title="Supplier Part Number"></kendo-grid-column>
            </kendo-grid>
        </section>
        <section class="grid-container">
            <kendo-grid class="grid-item-left" scrollable="none" [data]="[dataItem.addNewItemActionDetails]">
                <kendo-grid-column title="Hazardous">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.isHazardous">Yes</span>
                        <span *ngIf="!dataItem.isHazardous">No</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Critical">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.isCritical">Yes</span>
                        <span *ngIf="!dataItem.isCritical">No</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="MRP">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.isMRp">Yes</span>
                        <span *ngIf="!dataItem.isMRp">No</span>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
            <kendo-grid class="grid-item-right" [data]="dataItem.addNewItemActionDetails.storageLocations">
                <kendo-grid-column field="plantName" title="Plant"></kendo-grid-column>
                <kendo-grid-column field="storageLocatinName" title="Storage Location"></kendo-grid-column>
                <kendo-grid-column field="minimum" title="Minimum"></kendo-grid-column>
                <kendo-grid-column field="maximum" title="Maximum"></kendo-grid-column>
                <kendo-grid-column field="roundingValue" title="Rounding Value"></kendo-grid-column>
                <kendo-grid-column field="estimatedInventoryValue" title="Estimated Inventory Value (USD)">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        ${{dataItem.estimatedInventoryValue}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="specialProcurementKey" title="Special Procurement Key">
                </kendo-grid-column>
            </kendo-grid>
        </section>
        <section class="grid-container">
            <kendo-grid class="grid-item-left" [data]="dataItem.addNewItemActionDetails.stockingLocations">
                <kendo-grid-column field="code" title="Location"></kendo-grid-column>
                <kendo-grid-column field="min" title="Min"></kendo-grid-column>
                <kendo-grid-column field="max" title="Max"></kendo-grid-column>
                <kendo-grid-column field="stockHolding" title="Estimated Stock Holding"></kendo-grid-column>
            </kendo-grid>
        </section>
    </ng-template>
</kendo-grid>