import { GraphService } from './graph.service';
import { Inject, Injectable } from '@angular/core';
import { InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Observable, Subject } from 'rxjs';
import { ProfileType } from '../models/graph.models';

@Injectable({ providedIn: 'root' })
export class GlobalAuthService {    
    public profile = new Subject<ProfileType | null>(); 
    public loginDisplay = new Subject<boolean | null>();
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,        
        private garphService: GraphService
    ){
        this.getUserProfile();
        this.setLoginDisplay();
    }
    
    getUserProfile(): void {
        this.garphService.getAdUserProfile().subscribe(userprofile =>this.profile.next(userprofile));
    }
    
    getUserProfileDetails():Observable<ProfileType>{
       return this.garphService.getAdUserProfile();
    }

    setLoginDisplay(): void {
        this.loginDisplay.next(this.msalService.instance.getAllAccounts().length > 0);
    }

    logout(): void {
        if(this.msalGuardConfig.interactionType == InteractionType.Popup){
            this.msalService.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
              });
        } else {
            this.msalService.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
        this.profile.next(null);
        this.loginDisplay.next(null);
    }

}