
export const environment = {
  production: true,
  apiUrl: 'https://esar-api-test-cvx.azurewebsites.net/',
  azureAd: {
    clientId: '5d11c82c-3ebd-43c3-b88b-b74e0ed0edba',
    redirectUri: 'https://esar-test.azure.chevron.com/',
    authority: 'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    graphApis: {
      users: 'https://graph.microsoft.com/v1.0/users',
      me: 'https://graph.microsoft.com/v1.0/me'
    },
    downStreamApis:{
      baseUri:"https://esar-api-test-cvx.azurewebsites.net/",
      scope:"https://StockAuthorizationRequestAPI-dev.azure.chevron.com/ESARReadWrite"
    }
  }
};
