import { AddNewActionItemsAttachmentDetailsDisplay } from '../../models/common/action-attachment.model';
import { ApprovalDetailService } from '../../services/approvalDetail/approval-details.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FileConstant } from 'src/app/core/constants/commonConst/file.const';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';

@Component({
  selector: 'app-add-new-entry',
  templateUrl: './add-new-entry.component.html',
  styleUrls: ['./add-new-entry.component.scss'],
})
export class AddNewEntryComponent implements OnInit {
  data!: { itemsList: any, createdBy: any, modifiedBy: any, requestID: number, requestIssueID: number };
  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  listOfGroups: Array<string> = [];
  userList: Array<string> = [];
  selectedLine: Array<any> = [];
  selectedGroup: any
  selectedUser: any;
  entryComment: string = '';
  entryCommentLength: number = 2000;
  isGeneralCommnetActive: boolean = false;
  selecteLineItems: any[] = [];
  isGroupSelected: boolean = false;
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  fileName: string = '';
  uploadedFiles: any[] = [];
  isOpened: boolean = false;
  @ViewChild("multiselect", { static: true }) public multiselect: any;
  isLineSelected: boolean = false;
  filterItemList: Array<string> = [];
  userBUName: any;
  loggedInUser: string = '';
  userRole: string = '';
  isUserIsAdmin!: boolean;
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 0,
    name: "Select Group"
  };

  constructor(private _approvalDetailService: ApprovalDetailService, public commonService: CommonService,
    public dialog: DialogRef, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('userPrincipalName')!);
    this.loggedUserRole();
    this.getAssignmentGroupd();
    this.filterItemList = JSON.parse(JSON.stringify(this.data.itemsList));
  }

  getAssignmentGroupd() {
    this.userBUName = this.commonService.getUserBUName();
    this._approvalDetailService.getAssignedBUNames(this.userBUName).subscribe((data: any) => {
      this.listOfGroups = data;
    })
  }

  selectGroup(ele: any) {
    this.selectedGroup = ele?.id;
    this._approvalDetailService.getAssignedUsersList(ele?.id).subscribe((data: any) => {
      if (data) {
        this.isGroupSelected = true;
        this.userList = data;
      }
    })
  }

  async selectAll(ele: any) {
    const res = await this.mapDataWithMinMaxValue(ele);
    this.selecteLineItems = ele;
    this.selectedLine = res;
  }

  async selectLine(ele: any) {
    const res = await this.mapDataWithMinMaxValue(ele);
    this.selectedLine = res;
  }

  // to map data for min max value validation in line-items
  mapDataWithMinMaxValue(ele: any) {
    const res = ele.map((list: any) => {
      const data = {
        lineID: list.requestLineId,
        minMax: list.havingMnMaxValues
      }
      return data
    });
    return res
  }

  selectUser(ele: any) {
    this.selectedUser = ele?.id;
  }

  generalComment(event: any) {
    if (event) {
      this.isGeneralCommnetActive = true;
    } else {
      this.isGeneralCommnetActive = false;
    }
  }

  onFilesUpload(event: any) {
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    const files = event.target.files;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        this.uploadedFiles.push(files[0]);
      } else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  setNewAttachment(file: File): AddNewActionItemsAttachmentDetailsDisplay {
    const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = new AddNewActionItemsAttachmentDetailsDisplay();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }

  removeFile(index: number) {
    if (this.uploadedFiles && this.uploadedFiles[index]) {
      this.uploadedFiles!.splice(index, 1);
    }
  }

  downloadFile(index: number) {
    let attachment: any = this.uploadedFiles[index];
    const blob = new Blob([attachment.file!], { type: attachment.file!?.type });
    this.commonService.downloadFile(blob, attachment.name!);
  }

  saveEntry() {
    let formData: any;
    const minMaxErrorMSG = `Please review the selected lines as mix lines with & without min-max not allowed`;
    const errorMessage = `Please review the highlighted fields before proceeding`;
    if (this.isGeneralCommnetActive) {
      this.saveEntryAsGeneralCommnetActive();
    } else {
      if (this.selectedLine && this.selectedLine.length > 0 && this.entryComment) {
        // show error for min-max value only if selectedLine length > 1
        if (this.selectedLine.length > 1) {
          for (let line of this.selectedLine) {
            if (line.minMax == true) {
              this.commonService.showNotificationMessage(minMaxErrorMSG, 'error', 'center');
              return;
            }
          }
        }
        const requestLineId: any = this.selectedLine.map((ele: any) => {
          return ele.lineID;
        });
        formData = new FormData();
        formData.append('requestId', this.data.requestID!.toString());
        formData.append('requestIssueId', this.data.requestIssueID!.toString());
        formData.append('createdBy', this.loggedInUser!);
        formData.append('modifiedBy', this.data.modifiedBy!);
        formData.append('isGeneralComment', this.isGeneralCommnetActive.toString());
        formData.append('comment', this.entryComment!);
        if (this.isUserIsAdmin && this.selectedGroup) {
          formData.append('assignedGroupId', this.selectedGroup ? this.selectedGroup! : {});
        }
        if (this.isUserIsAdmin && this.selectedUser) {
          formData.append('assignedUserId', this.selectedUser ? this.selectedUser! : {});
        }
        for (let line of requestLineId!) {
          formData.append('requestLineIds', line);
        }
        if (this.uploadedFiles) {
          for (let attachment of this.uploadedFiles!) {
            formData.append('attachments', attachment);
          }
        } else {
          formData.append('attachments', []);
        }

        this._approvalDetailService.addNewEntry(formData).subscribe((data: any) => {
          this.commonService.showNotificationMessage(data, 'success', 'center');
          this.close('success');
        })
      } else {
        this.commonService.showNotificationMessage(errorMessage, 'error', 'center');
      }
    }
  }

  // save entry when general comment is true
  saveEntryAsGeneralCommnetActive() {
    const errorMessage = `Please review the highlighted fields before proceeding`;
    let formData: any;
    if (this.entryComment) {
      formData = new FormData();
      formData.append('requestId', this.data.requestID!.toString());
      formData.append('requestIssueId', this.data.requestIssueID!.toString());
      formData.append('createdBy', this.loggedInUser!);
      formData.append('modifiedBy', this.data.modifiedBy!);
      formData.append('isGeneralComment', this.isGeneralCommnetActive.toString());
      formData.append('comment', this.entryComment!);
      if (this.uploadedFiles) {
        for (let attachment of this.uploadedFiles!) {
          formData.append('attachments', attachment);
        }
      } else {
        formData.append('attachments', []);
      }

      this._approvalDetailService.addNewEntry(formData).subscribe((data: any) => {
        this.commonService.showNotificationMessage(data, 'success', 'center');
        this.close('success');
      })
    }
    else {
      this.commonService.showNotificationMessage(errorMessage, 'error', 'center');
    }
  }

  close(status: any) {
    this.dialog.close(status);
  }

  searchLine(event: any) {
    const filteredItems = this.filterItemList.filter((ele: any) => {
      return ele?.lineNumber?.toLowerCase().includes(event.target.value.toLowerCase());
    })
    this.data.itemsList = filteredItems;
  }

  onOpen(event: any) {
    let multiselect;
    multiselect = this.el?.nativeElement?.querySelector("#multiSelect-dropdown");
    this.renderer?.addClass(multiselect, 'opened')
  }

  onClose(event: any) {
    let multiselect;
    multiselect = this.el?.nativeElement?.querySelector("#multiSelect-dropdown");
    this.isOpened = false;
    this.renderer?.removeClass(multiselect, 'opened');
  }

  toggle() {
    this.isOpened = !this.isOpened;
    this.multiselect?.toggle(this.isOpened);
  }

  loggedUserRole() {
    this.userRole = this.commonService.getUserRoleName();
    switch (this.userRole) {
      case RoleConstant.ESAR_Admin: {
        this.isUserIsAdmin = true;
        break;
      }
      default: {
        this.isUserIsAdmin = false;
        break;
      }
    }
  }
}
