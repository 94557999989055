import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpUtilityService {
    apiurl = environment.apiUrl;
    constructor(private http: HttpClient) {
    }

    public get<T>(url: string, options?: object) {
        return this.http.get<T>(this.apiurl + url, options);
    }
    public getAll<T>(url: string, options?: object) {
        return this.http.get<T>(this.apiurl + url, options);
    }
    public getBy<T>(url: string, data: T, options?: object) {
        return this.http.post<T>(this.apiurl + url, data, options);
    }
    public getByAll<T>(url: string, options?: object) {
        return this.http.get<T>(this.apiurl + url, options);
    }
    public post<T>(url: string, data: T, options?: object) {
        return this.http.post<T>(this.apiurl + url, data, options);
    }
    public postByAll<T>(url: string, data: T, options?: object) {
        return this.http.post<T[]>(this.apiurl + url, data, options);
    }
    public put<T>(url: string, data: T | null, options?: object) {
        return this.http.put<T>(this.apiurl + url, data, options);
    }
    public delete<T>(url: string, options?: object) {
        return this.http.delete<T>(this.apiurl + url, options);
    }
}