import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StockingLocation } from '../models/stockinglocation/stockinglocation.model';
import { StockingLocationConstant } from 'src/app/core/constants/stocking-location.constant';

@Injectable({
  providedIn: 'root'
})
export class StockingLocationsService {
  constructor(private httpUtilityService: HttpUtilityService) {
  }

  getStockingLocations(): Observable<StockingLocation[]> {
    return this.httpUtilityService.getAll(StockingLocationConstant.stockingLocationsApi);
  }

  upsertStockingLocation(stockingLocations: StockingLocation[]) {
    return this.httpUtilityService.postByAll(StockingLocationConstant.stockingLocationsApi + StockingLocationConstant.upsert, stockingLocations);
  }

  addStockingLocation(stockingLocation: StockingLocation) {
    return this.httpUtilityService.post(StockingLocationConstant.stockingLocationsApi, stockingLocation);
  }

  updateStockingLocation(stockingLocation: StockingLocation) {
    return this.httpUtilityService.put(StockingLocationConstant.stockingLocationsApi, stockingLocation);
  }

}
