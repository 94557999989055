
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export abstract class HttpAbstractService<T> {
    apiurl = environment.apiUrl;
    constructor(protected http: HttpClient) {
    }

    public get(url: string, options?: object) {
        return this.http.get<T>(this.apiurl + url, options);
    }
    public getAll(url: string, options?: object) {
        return this.http.get<T[]>(this.apiurl + url, options);
    }
    public getBy(url: string, data: T, options?: object) {
        return this.http.post<T>(this.apiurl + url, data, options);
    }
    public getByAll(url: string, options?: object) {
        return this.http.get<T[]>(this.apiurl + url, options);
    }
    public postByAll<T>(url: string, data: T, options?: object) {
        return this.http.post<T[]>(this.apiurl + url, data, options);
    }
    public post(url: string, data: T, options?: object) {
        return this.http.post<T>(this.apiurl + url, data, options);
    }
    public postAll(url: string, data: T[], options?: object) {
        return this.http.post<T>(this.apiurl + url, data, options);
    }
    public put(url: string, Id: number, data: T, options?: object) {
        return this.http.put<T>(this.apiurl + url + '/' + Id, data);
    }
    public delete(url: string, options?: object) {
        return this.http.delete<T>(this.apiurl + url, options);
    }
}