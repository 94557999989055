export const StockingLocationConstant = {
  stockingLocationsApi: 'api/StockingLocations/',
  upsert: 'Upsert',
  importMessage: 'Stocking Locations have been successfully imported.',
  ManageStockingLocationsPath: 'ManageStockingLocations',
  StockingLocationsPath: 'StockingLocations',
  createText: 'New Stocking Location',
  updateText: 'Update Stocking Location'
};

export const StockingLocationOperationConst = {
  create: 1,
  update: 2,
  delete: 3
};
