export const SARUsersConstant = {
    SARUsers: 'api/User',
    GetSAROwnersByBUId: '/GetSAROwnersByBUId',
    GetAllUserDetailsByEmail: '/GetAllUserDetailsByEmail',
    getAssignedUsers: '/GetAssignedUsers',
    SARUserRoute: 'SARUsers',
    ManageSARUserRoute: 'ManageSARUsers',
    bulkInsertMessage: 'SAR Users are successfully imported',
    emailError: 'Please enter Email.',
    emailNotFoundError: 'Email enatered not found.',
    emailAlreadyExistError: 'Email already active, Please enter another Email.',
    invalidEmailError: 'Email entered is invalid, Please enter valid Email.',
    objectIdNotFoundError: 'UserId not email for the Email.',
    searchFieldName: 'name',
    email: 'email'
    // caiError: 'Please enter CAI.', /* Commentted for 7136137: CAI Deprecation */
    // caiLengthError: 'CAI should be of 4 characters length, Please enter valid CAI.', /* Commentted for 7136137: CAI Deprecation */
    // invalidCAIError: 'CAI entered is invalid, Please enter valid CAI.', /* Commentted for 7136137: CAI Deprecation */
};