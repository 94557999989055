import { CommonService } from 'src/app/core/services/common.service';
import { DashboardConst } from 'src/app/core/constants/dashboard.const';
import { DashboardRequestDetailsDto } from '../models/sar-request/dashboard-request-details.models';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestsAssignedToMeCountDto } from '../models/sar-request/requests-count/requests-assigned-to-me-count.model';
import { RequestsCountDto } from '../models/sar-request/requests-count/requests-created-by-me-count.model';
import { RequestsIssueTypeCountDto } from '../models/sar-request/requests-count/requests-issue-type-count.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    email: string = '';
    buName: string = '';

    constructor(private commonService: CommonService, private httpUtilityService: HttpUtilityService) {
        this.email = this.commonService.getUserEmailId();
        this.buName = this.commonService.getUserBUName();
    }

    getCountOfRequestsCreatedByMe(isMyBU: boolean, searchText: string): Observable<RequestsCountDto> {
        return this.httpUtilityService.get<RequestsCountDto>(DashboardConst.ApiName + DashboardConst.getCountOfRequestsCreatedByMe +
            `?isMyBU=${isMyBU}&buName=${this.buName}&userName=${this.email}&searchText=${searchText}`);
    }

    getCountOfRequestsAssignedToGroup(groupId: number, searchText: string): Observable<RequestsCountDto> {
        return this.httpUtilityService.get<RequestsCountDto>(DashboardConst.ApiName + DashboardConst.getCountOfRequestsAssignedToGroup +
            `?groupId=${groupId}&buName=${this.buName}&searchText=${searchText}`);
    }

    getCountOfRequestsAssignedToMe(searchText: string): Observable<RequestsAssignedToMeCountDto> {
        return this.httpUtilityService.get<RequestsAssignedToMeCountDto>(DashboardConst.ApiName + DashboardConst.getCountOfRequestsAssignedToMe +
            `?buName=${this.buName}&userName=${this.email}&searchText=${searchText}`);
    }

    getCountOfRequestsIssueTypes(isMyBU: boolean, requestCategory: number, stateId: number, searchText: string): Observable<RequestsIssueTypeCountDto> {
        return this.httpUtilityService.get<RequestsIssueTypeCountDto>(DashboardConst.ApiName + DashboardConst.getCountOfRequestIssueTypes +
            `?isMyBU=${isMyBU}&buName=${this.buName}&requestCategory=${requestCategory}&requestState=${stateId}&userName=${this.email}&searchText=${searchText}`)
    }

    getRequestDetails(isMyBU: boolean, requestCategory: number, stateId: number, requestIssueType: number, searchText: string, groupId: number): Observable<DashboardRequestDetailsDto[]> {
        return this.httpUtilityService.get<DashboardRequestDetailsDto[]>(DashboardConst.ApiName + DashboardConst.getRequestDetails +
            `?isMyBU=${isMyBU}&buName=${this.buName}&requestCategory=${requestCategory}&requestState=${stateId}` + (requestIssueType ? `&requestIssueType=${requestIssueType}` : '') + `&userName=${this.email}&searchText=${searchText}` + `&groupId=${groupId}`)
    }

}