import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { ApprovalMMDActionDetail, ApprovalSAActionDetail } from '../../../models/approval-details/action-detail.model';
import { BulkActionComponent } from 'src/app/core/components/bulk-action/bulk-action.component';
import { ChangeDataActionResponseDto } from '../../../models/change-sar/change-data-action-response.model';
import { ChangeDataApprovalExcelColumn } from 'src/app/core/constants/excel-columns-const';
import { ChangeDataConst } from 'src/app/core/constants/change-data.const';
import { ChangeDataItem } from '../../../models/change-sar/change-data-item.model';
import { ChangeDataService } from '../../../services/change-data.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { SelectionEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-change-data-approval',
  templateUrl: './change-data-approval.component.html',
  styleUrls: ['./change-data-approval.component.scss']
})
export class ChangeDataApprovalComponent {
  @Input() public requiredParams: NewActionInputParam = {};
  @Input() public currentActionName: string = '';
  @Input() public dummyInput: number = 0;
  changeDataDetails: ChangeDataActionResponseDto = new ChangeDataActionResponseDto();
  @Output() saActionEvent = new EventEmitter<ApprovalSAActionDetail>();
  @Output() mmdActionEvent = new EventEmitter<ApprovalMMDActionDetail>();
  @Output() mmdBulkActionEvent = new EventEmitter<ApprovalMMDActionDetail[]>();
  @Output() saBulkActionEvent = new EventEmitter<ApprovalSAActionDetail[]>();
  mySelection: number[] = [];
  changeDataApprovalExcelColumn: any = ChangeDataApprovalExcelColumn;
  isBulk = true;
  constructor(public router: Router,
    private route: ActivatedRoute, private changeDataService: ChangeDataService, private dialogService: DialogService,
    public commonService: CommonService) { }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.getChangeData();
  }

  openPopup() {
    const dialog: DialogRef = this.dialogService.open({
      content: BulkActionComponent
    });
    const inc = dialog.content.instance as BulkActionComponent;
    inc.isMMD = this.currentActionName === 'mmd-action' ? true : false;
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.action) || r.action === 'Close') {
        return false;
      }
      this.bulkAction(r.action, r.comment);
    });
  }

  bulkAction(actionType: string, comment: string) {
    if (this.currentActionName === 'mmd-action') {
      let approvalMMDActionDetail: ApprovalMMDActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.changeDataDetails.items[selectedValue];
        if (dataItem.status !== 'Pending MMD') {
          continue;
        }
        dataItem.comment = comment;
        let approvalActionDetail = this.getmmdValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.mmdAction = 3;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.mmdAction = 1;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.mmdAction = 2;
        }
        approvalMMDActionDetail.push(approvalActionDetail);
      }
      this.mySelection = [];
      this.mmdBulkActionEvent.emit(approvalMMDActionDetail);
    }
    if (this.currentActionName === 'sa-action') {
      let approvalSAActionDetail: ApprovalSAActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.changeDataDetails.items[selectedValue];
        if (dataItem.status !== 'PendingStockingApproval') {
          continue;
        }
        dataItem.comment = comment;
        let approvalActionDetail = this.getsaValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.stockApproverAction = 3;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.stockApproverAction = 1;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.stockApproverAction = 2;
        }
        approvalSAActionDetail.push(approvalActionDetail);
      }
      this.mySelection = [];
      this.saBulkActionEvent.emit(approvalSAActionDetail);
    }
  }

  clickCheckBox(e: SelectionEvent) {
    this.isBulk = true;
    for (let selected of this.mySelection) {
      if (this.changeDataDetails.items[selected].status === 'Pending MMD' ||
        this.changeDataDetails.items[selected].status === ApprovalDetailsConstants.PendingStockingApproval
      ) {
        this.isBulk = false;
        break;
      }
    }
  }

  getChangeData() {
    this.changeDataService.getChangeDataDetail(this.requiredParams.requestId!,
      this.requiredParams.requestIssueId!).subscribe((outputDtata: ChangeDataActionResponseDto) => {
        outputDtata.items = outputDtata.items.filter(x => x.status !== 'Draft');
        if (this.currentActionName === 'sa-action') {
          this.changeDataDetails = new ChangeDataActionResponseDto();
          return false;

        }
        this.changeDataDetails = outputDtata;
        this.mySelection = []
        if (this.currentActionName !== 'summary') {
          this.changeDataDetails.items = this.changeDataDetails.items.filter(x => x.status !== 'Rejected' && x.status != 'Cancelled');
        }
      });
  }

  assignEmpty(dataItem: any) {
    dataItem.comment = dataItem.comment == undefined ? '' : dataItem.comment
  }

  cancel(dataItem: ChangeDataItem) {
    dataItem.isCommentError = false;
    if (this.commonService.isNullOrEmpty(dataItem.comment!)) {
      dataItem.isCommentError = true;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          this.mySelection = [];
          let approvalActionDetail = this.getmmdValues(dataItem);
          approvalActionDetail.mmdAction = 3;
          this.mmdActionEvent.emit(approvalActionDetail);
        }
      })
  }

  reject(dataItem: ChangeDataItem) {
    dataItem.isCommentError = false;
    if (this.commonService.isNullOrEmpty(dataItem.comment!)) {
      dataItem.isCommentError = true;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.commonService.showConfirmation('Rejection', ApprovalDetailsConstants.rejectMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          this.mySelection = [];
          let approvalActionDetail = this.getmmdValues(dataItem);
          approvalActionDetail.mmdAction = 1;
          this.mmdActionEvent.emit(approvalActionDetail);
        }
      })
  }

  complete(dataItem: ChangeDataItem) {
    if (this.currentActionName === 'mmd-action') {
      this.commonService.showConfirmation('Complete', ApprovalDetailsConstants.completeMessage)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            this.mySelection = [];
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 2;
            this.mmdActionEvent.emit(approvalActionDetail);
          }
        }
        )
    }
  }

  getmmdValues(dataItem: ChangeDataItem): ApprovalMMDActionDetail {
    let approvalMMDActionDetail: ApprovalMMDActionDetail = new ApprovalMMDActionDetail()
    approvalMMDActionDetail.comment = dataItem.comment;
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.enrichedDescription = '';
    approvalMMDActionDetail.materialNumber = '';
    approvalMMDActionDetail.shortDescription = ''
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 2;
    approvalMMDActionDetail.requestLineId = dataItem.id;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }

  getsaValues(dataItem: ChangeDataItem): ApprovalSAActionDetail {
    let approvalMMDActionDetail: ApprovalSAActionDetail = new ApprovalSAActionDetail()
    approvalMMDActionDetail.comment = dataItem.comment;
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 2;
    approvalMMDActionDetail.requestLineId = dataItem.id;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }
  public exportToExcel() {
    let date = new Date().toISOString();
    date = date.substring(0, date.lastIndexOf(':')).replace('T', '-').replace(':', '-');
    let fileName = this.requiredParams.requestNumber + '-Action-' + this.requiredParams.actionNumber + '-' + 'Item-Export-' + date;
    this.commonService.exportAsExcelFile(this.changeDataDetails!.items, this.changeDataApprovalExcelColumn, fileName)
  }
}
