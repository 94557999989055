import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SARUsers } from 'src/app/features/setup/models/sarusers/sarusers.model';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { UserDetailDto } from '../../models/user-detail.model';

export type ProfileFormType<T> = {
  [K in keyof T]?: FormControl;
}

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  profileForm: FormGroup;
  assignToUsers: SARUsers[] = [];
  isFormFilled: boolean = false;

  readonly emailDomain: string;

  private _adUserEmail: string = JSON.parse(sessionStorage.getItem("userPrincipalName")!);

  constructor(private usersService: SarUsersService,
              private formBuilder: FormBuilder
  ) {
    this.profileForm = this.getProfileForm();
    this.emailDomain = "@" + this.splitEmail(this._adUserEmail)[1];
  }

  public get isFormInvalid(): boolean {
    return this.profileForm.invalid;
  }

  public get emailError(): string {
    const errors = this.profileForm.controls['email'].errors;

    if (this.isFormFilled && errors?.['required'])
      return "Email is required";

    if (this.isFormFilled && errors?.['pattern'])
      return "Email is invalid";

    return "";
  }

  ngOnInit(): void {
    const $users = this.usersService.getSARUsers();
    const $userDetails = this.usersService.getUserDetails(this._adUserEmail);

    forkJoin([$users, $userDetails]).subscribe(([users, userDetails]) => {
      this.assignToUsers = users.filter(user => user.name !== null);
      this.fillProfileForm(userDetails);
    });
  }

  public reset(): void {
    const userDetails = {
      ...this.createUserDetailRequest(),
      email: this._adUserEmail,
      secondEmail: "",
      isSendNotification: false,
      isDelegationActive: false,
      delegatedUserId: this.assignToUsers[0].id
    } as UserDetailDto;

    this.saveRequest(userDetails);
  }

  public save(): void {
    if (this.isFormInvalid)
      return;

    const userDetails = this.createUserDetailRequest();

    this.saveRequest(userDetails);
  }

  private getProfileForm(): FormGroup {
    const form: ProfileFormType<UserDetailDto> = {
      id: new FormControl(<number>(0)),
      userId: new FormControl(<number>(0)),
      email: new FormControl(<string>(''), [Validators.required, Validators.pattern(/^[a-zA-Z0-9.]*$/)]),
      secondEmail: new FormControl(<string | undefined>(''), Validators.pattern(/^[a-zA-Z0-9.]*$/)),
      isSendNotification: new FormControl(<boolean>(false)),
      isDelegationActive: new FormControl(<boolean>(false)),
      delegatedUserId: new FormControl(<number | null>(null))
    }

    return this.formBuilder.group(form);
  }

  private fillProfileForm(userDetails: UserDetailDto) {
    this.profileForm.setValue({
      id: userDetails?.id ?? 0,
      userId: userDetails?.userId ?? 0,
      email: userDetails?.email?.split("@")[0] ?? this._adUserEmail.split("@")[0],
      secondEmail: userDetails?.secondEmail?.split("@")[0] ?? "",
      isSendNotification: userDetails?.isSendNotification ?? false,
      isDelegationActive: userDetails?.isDelegationActive ?? false,
      delegatedUserId: userDetails?.delegatedUserId ?? this.assignToUsers[0].id,
    });

    this.isFormFilled = true;
  }

  private createUserDetailRequest(): UserDetailDto {
    return {
      id: this.profileForm.get('id')?.value,
      userId: this.profileForm.get('userId')?.value,
      email: this.getEmailFullName(this.profileForm.get('email')?.value),
      secondEmail: this.getEmailFullName(this.profileForm.get('secondEmail')?.value),
      isSendNotification: this.profileForm.get('isSendNotification')?.value,
      isDelegationActive: this.profileForm.get('isDelegationActive')?.value,
      delegatedUserId: this.profileForm.get('delegatedUserId')?.value,
      createdBy: this.getEmailFullName(this.profileForm.get('email')?.value),
      modifiedBy: this.getEmailFullName(this.profileForm.get('email')?.value),
      isActive: true,
    } as UserDetailDto;
  }

  private saveRequest(request: UserDetailDto): void {
    this.usersService.saveUserDetails(request).subscribe(details => {
      this.fillProfileForm(details);
    });
  }

  private splitEmail(email: string): string[] {
    return email.split("@");
  }

  private getEmailFullName(input: string | undefined | null): string {
    if (!input)
      return "";

    const emailDomain = this.splitEmail(this._adUserEmail)[1];

    return input + `@${emailDomain}`;
  }

}