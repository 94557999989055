<div class="MainPopup" *ngIf="result.length !== 0 && !isLoader">
    <div class="popup-head-close">
        <h2 class="m-0">Please select a Role</h2>
        <h2 class="m-0"><i class="fa fa-times" (click)="cancel()" aria-hidden="true"></i></h2>
    </div>
    <table class="role-table mt-15">
        <tr class="roleItem">
            <th colspan="2">
                <h5 class="font-size-h6 m-0"> Role</h5>
            </th>
        </tr>
        <tr class="roleItem" *ngFor="let item of roleData">
            <td class="roleItemText"> <input class="mr-10" size="medium" type="radio" name="role" value="{{item.name}}"
                    #male [(ngModel)]="selectedRole" kendoRadioButton />
            </td>
            <td>{{item.name}}</td>
        </tr>
    </table>
    <div class="mt-15 text-center">
        <input type="submit" name="wt11" (click)="cancel()" value="Cancel" id="wt11"
            class="border-button mr-20 rouded-border">
        <input type="submit" name="wt7" (click)="Save()" value="Select Role" id="wt7"
            class="primary-button Is_Default rouded-border">
    </div>
</div>

<div class="MainPopup" *ngIf="result.length === 0 && isLoader">
    <div style="padding-left: 45%;"><kendo-loader [type]="loader.type" [themeColor]="loader.themeColor"
            [size]="loader.size">
        </kendo-loader>
    </div>
</div>
