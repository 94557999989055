import { AssignmentDetailsDto } from '../models/approval-details/assignment-detail.model';
import { DeleteLineItemDto } from '../models/common/delete-line-item.model';
import { EquipmentProperty } from '../models/common/equipment-property.model';
import { HttpHeaders } from '@angular/common/http';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecommendedStockingLocationDto } from '../../setup/models/stocking-location.model.ts/stocking-location.model';
import { requestIssueConst } from 'src/app/core/constants/request-Issue.const';
import { RequestIssueDetails } from '../models/sar-request/request-issue/request-issue-details.model';
import { requestLineConst } from 'src/app/core/constants/request-line.const';

@Injectable({
  providedIn: 'root'
})
export class RequestLineService {  
  constructor(private httpUtilityService: HttpUtilityService) {
  }

  getActions(requestNumber: string): Observable<RequestIssueDetails[]> {
    return this.httpUtilityService.getAll<RequestIssueDetails[]>(requestIssueConst.apiName + requestIssueConst.getActions + '/' + requestNumber);
  }

  UpdateLineAssignment(data: FormData) {
    return this.httpUtilityService.post(requestLineConst.apiName + requestLineConst.updateLineAssignment, data);
  }

  updateLineAssignmentForRejected(data: FormData) {
    return this.httpUtilityService.post(requestLineConst.apiName + requestLineConst.updateLineAssignmentForRejected, data);
  }

  DeleteLineItems(data: DeleteLineItemDto) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(data)
    }

    return this.httpUtilityService.delete(requestLineConst.apiName + requestLineConst.deleteLineItems, httpOptions);
  }

  getAssignmentDetailsByRequestLineId(requestLineId: number): Observable<AssignmentDetailsDto> {
    return this.httpUtilityService.getAll(requestLineConst.apiName + requestLineConst.getAssignmentDetailsByRequestLineId + `/${requestLineId}`);
  }

  getEquipment(phrase: string): Observable<string[]>{
    return this.httpUtilityService.getAll(requestLineConst.apiName + requestLineConst.getEquipmentNames + `?phrase=${phrase}`);
  }

  getEquipmentProperties(equipmentTags: string) : Observable<EquipmentProperty> {
    return this.httpUtilityService.get<EquipmentProperty>(requestLineConst.apiName + requestLineConst.getEquipmentProperty + `?equipmentTags=${equipmentTags}`);
  }

  getRecommendedStockingLocations(requestId: number) : Observable<RecommendedStockingLocationDto[]> {
    return this.httpUtilityService.get<RecommendedStockingLocationDto[]>(requestLineConst.apiName + requestLineConst.getRecommendedStockingLocations + `/${requestId}`);
  }
 
}