import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import { CommonService } from '../../services/common.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { SharedConst } from '../../constants/shared.constant';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrls: ['./dialog-upload.component.css']
})
export class DialogUploadComponent {

  dataString = '';
  isEdit: boolean = false;
  fileName: string = '';

  constructor(public dialog: DialogRef, public commonService: CommonService) { }

  upload(evt: any) {
    if (evt.target.files.length === 0) {
      this.commonService.showNotificationMessage(SharedConst.fileRequired, 'error', 'right')
      return false;
    }

    if (evt.target.files[0].name.split('.')[1] !== 'xlsx') {
      this.commonService.showNotificationMessage(SharedConst.fileFormat, 'error', 'right')
      return false;
    }

    let workBook: XLSX.WorkBook;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.target.files[0];
    this.isEdit = file.name.search('edit') > 0 ? true : false;
    this.fileName = evt.target.files[0].name;
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.dataString = JSON.stringify(jsonData);
    }
    reader.readAsBinaryString(file);
  }

  Save() {
    if (!this.dataString) {
      this.commonService.showNotificationMessage(SharedConst.fileRequired, 'error', 'right')
      return false;
    }
    this.dialog.close({ action: 'Save', value: this.dataString, isEdit: this.isEdit, fileName: this.fileName });
  }

  cancel() {
    this.dialog.close({ action: 'Cancel', value: {} });
  }

}