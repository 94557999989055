import { catchError, tap } from 'rxjs/operators';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SARUsers } from '../models/sarusers/sarusers.model';
import { SARUsersConstant } from 'src/app/core/constants/sar-users.constants';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { UserAndRoleConst } from 'src/app/core/constants/userandrole.const';
import { UserDetailDto } from 'src/app/core/models/user-detail.model';
@Injectable({
    providedIn: 'root'
})
export class SarUsersService extends HttpAbstractService<SARUsers> {

    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }

    getSARUsers() {
        return this.getAll(SARUsersConstant.SARUsers);
    }

    GetSAROwnersByBUId(buName: string) {
        return this.getAll(SARUsersConstant.SARUsers + SARUsersConstant.GetSAROwnersByBUId + '/' + buName.toString());
    }

    GetAllUserDetailsByEmail(email: string) {
        return this.get(SARUsersConstant.SARUsers + SARUsersConstant.GetAllUserDetailsByEmail + '/' + email.toString());
    }

    getUserDetails(userEmail: string): Observable<UserDetailDto> {
        const url = UserAndRoleConst.userAPI + UserAndRoleConst.getProfileInfo;

        return this.httpUtilityService.get<UserDetailDto>(url + `/${userEmail}`);
    }

    saveSARUsers(data: SARUsers) {
        return this.post(SARUsersConstant.SARUsers, data).pipe(
            tap(response => { return response }),
            catchError((err: HttpErrorResponse) => { return throwError(() => err); }));
    }

    saveUserDetails(details: UserDetailDto): Observable<UserDetailDto> {
        const url = UserAndRoleConst.userAPI + UserAndRoleConst.saveProfileInfo;
        return this.httpUtilityService.post(url, details);
    }

    updateSARUsers(data: SARUsers) {
        return this.put(SARUsersConstant.SARUsers, data.id, data);
    }

    importExcelData(data: SARUsers[]) {
        return this.postAll(SARUsersConstant.SARUsers + SharedConst.importApiName, data);
    }

    getAssignedUsers(groupId: number) {
        return this.getAll(SARUsersConstant.SARUsers + SARUsersConstant.getAssignedUsers + `/${groupId}`);
    }

}