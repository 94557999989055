import { BusinessUnitDto } from '../models/bussinessunits/bussinessunits.model';
import { BussinesUnitConst } from 'src/app/core/constants/bussinessunit.const';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpClient } from '@angular/common/http';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Injectable } from '@angular/core';
import { SharedConst } from 'src/app/core/constants/shared.constant';

@Injectable({
    providedIn: 'root',
})
export class BussinessUnitService extends HttpAbstractService<BusinessUnitDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }
    getBUAll() {
        return this.getAll(BussinesUnitConst.BUApiName);
    }
    getBUById(id:number){
        return this.get(BussinesUnitConst.BUApiName + '/' + id);
    }
    saveBU(data: BusinessUnitDto) {
        return this.post(BussinesUnitConst.BUApiName, data);
    }
    UpdateBU(data: BusinessUnitDto) {
        return this.put(BussinesUnitConst.BUApiName, data.id, data);
    }
    importExcelData(data: BusinessUnitDto[]) {
        return this.postAll(BussinesUnitConst.BUApiName + SharedConst.importApiName, data);
    }
}