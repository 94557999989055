import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';
import { AddItemsExcelColumnGOMBU } from '../../../../core/constants/excel-columns-const';
import { AddNewActionItemsAttachmentDetails, AddNewActionItemsAttachmentDetailsDisplay } from '../../models/common/action-attachment.model';
import { AddNewActionService } from '../../services/add-new-action.service';
import { AddNewItemAction } from '../../models/add-new-action/add-new-action-item.model';
import { AddNewItemActionResponseDto } from '../../models/add-new-action/add-new-item-action-list.model';
import { AddNewItemConstant } from 'src/app/core/constants/add-new-item.constant';
import { AddNewItemDto } from '../../models/add-new-item/add-new-item.model';
import { AddNewItemService } from '../../services/add-new-item.service';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { ApprovalDetailService } from '../../services/approvalDetail/approval-details.service';
import { BlobURlsDetails } from 'src/app/core/constants/commonConst/blobUrl.cons';
import { BulkCancelComponent } from 'src/app/core/components/bulk-cancel/bulk-cancel.component';
import { BusinessUnitDto } from 'src/app/features/setup/models/bussinessunits/bussinessunits.model';
import { BussinessUnitService } from 'src/app/features/setup/services/bussinessunit.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeleteLineItemDto } from '../../models/common/delete-line-item.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { EquipmentDataService } from '../../models/common/equipment-data.service';
import { EquipmentDto } from '../../models/common/equipment.model';
import { ExtensionMRPUpdateService } from '../../services/extensionMRPupdate.service';
import { FileConstant } from 'src/app/core/constants/commonConst/file.const';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { LineAssignmentDto } from '../../models/common/line-assignment.model';
import { MaterialGroupCode } from 'src/app/features/setup/models/materialgroupcode/materialgroupcode.model';
import { MaterialGroupCodeService } from 'src/app/features/setup/services/material-group-code.service';
import { MaterialStatus } from 'src/app/features/setup/models/materialstatus/materialstatus.model';
import { MaterialStatusService } from 'src/app/features/setup/services/material-status.service';
import { MaterialType } from 'src/app/features/setup/models/materialtype/materialtype.model';
import { MaterialTypeService } from 'src/app/features/setup/services/material-type.service';
import { MMDActionDto } from '../../models/approval-details/mmd-approval-caction.mode';
import { NewActionInputParam } from '../../models/common/new-action-input-param.model';
import { PlantDetailsDto } from '../../models/common/plant-detail.model';
import { QACodeDto } from 'src/app/features/setup/models/qacode/qacode.model';
import { QACodeService } from 'src/app/features/setup/services/qacode.service';
import { requestConst } from '../../../../core/constants/request.const';
import { requestIssueConst } from 'src/app/core/constants/request-Issue.const';
import { RequestIssueService } from '../../services/request-issue.service';
import { RequestIssueTypeDto } from '../../models/sar-request/request-issue/request-issue-types.model';
import { RequestLineQACode } from '../../models/add-new-item/request-line-qa-code.model';
import { RequestLineService } from '../../services/request-line.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { SegmentDto } from 'src/app/features/setup/models/segment/segment.model';
import { SegmentService } from 'src/app/features/setup/services/segment.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { SPKDto } from 'src/app/features/setup/models/spk/spk.model';
import { SPKService } from 'src/app/features/setup/services/spk.service';
import { StorageConditionDto } from 'src/app/features/setup/models/storagecondition/storagecondition.model';
import { StorageCondtionService } from 'src/app/features/setup/services/storagecondition.service';
import { StorageLocationDetailsDto } from '../../models/common/storage-location-detail.model';
import { StorageLocationDto } from '../../models/add-new-item/storage-location.model';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { SupplierDto } from 'src/app/features/setup/models/supplier/supplier.model';
import { SupplierService } from 'src/app/features/setup/services/supplier.service';
import { TempActionRequestDto } from '../../models/temp-actions/temp-action-request.module';
import { TempActionService } from '../../services/temp-action.service';
import { UnitOfMeasure } from 'src/app/features/setup/models/unitofmeasure/unitofmeasure.model';
import { UnitOfMeasureService } from 'src/app/features/setup/services/unit-of-measure.service';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { UserGroupsService } from 'src/app/features/setup/services/user-groups.service';

@Component({
    selector: 'app-add-new-action',
    templateUrl: './add-new-action.component.html',
    styleUrls: ['./add-new-action.component.scss']
})
export class AddNewActionComponent implements OnInit {
    requestAddNewActionItemDetails: AddNewItemActionResponseDto | undefined;
    requestIssueId: number = 0;
    items: AddNewItemAction[] = []
    selectedItems: any = [];
    excelFileTemplatePath: string = '';
    excelFileTemplateName: string = '';
    actionItems: AddNewItemDto[] = [];
    uomList: UnitOfMeasure[] = [];
    materialTypes: MaterialType[] = [];
    materialGroupCodeList: MaterialGroupCode[] = [];
    materialStatusList: MaterialStatus[] = [];
    storageConditionList: StorageConditionDto[] = [];
    segmentList: SegmentDto[] = [];
    plantList: PlantDetailsDto[] = [];
    qaCodeList: QACodeDto[] = [];
    SPKList: SPKDto[] = [];
    public supplierList: SupplierDto[] = [];
    @Input() public requiredParams: NewActionInputParam = {};
    buName: string = '';
    uploadErrors: string = '';
    countErrors: number = 0;
    equipmentCriticality: string;
    duplicatePlant_StorageLocation: boolean = false;
    isDraftavailable: boolean = false;
    @Output() isDraftavailableEvent = new EventEmitter<boolean>();
    attachmentDetails?: AddNewActionItemsAttachmentDetails[] = [];
    attachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
    @Output() cancelEvent = new EventEmitter<DeleteLineItemDto>();
    comment: string = '';
    businessUnitId: number = 0;
    requestIssueTypeId: number = 0;
    acceptedFiles: string[] = FileConstant.acceptedFiles;
    accptedFilesStr: string = this.acceptedFiles.join(',');
    @ViewChild('fileInput') fileInput: any;
    tempActionRequest!: TempActionRequestDto;
    @Output() tempActionRequestEvent = new EventEmitter<TempActionRequestDto>();
    lineAssignment!: LineAssignmentDto;
    @Output() lineAssignmentEvent = new EventEmitter<LineAssignmentDto>();
    assignToGroups: Group[] = [];
    assignToUsers: UserDto[] = [];
    enabledAssignToUser: boolean = false;
    selectedUser = 0;
    public defaultAssignToGroup: { name: string; id: number } = {
        id: 0,
        name: "Select Group"
    };
    public defaultAssignToUser: { name: string; id: number } = {
        id: 0,
        name: "-"
    };

    data: EquipmentDto = {
        itemPartRanking: undefined,
        equipmentCriticality: undefined,
        equipmentTags: []
    };
    subscription: Subscription;

    deleteLineItem: DeleteLineItemDto = new DeleteLineItemDto();
    @Input() public isGoupSelected: boolean = true;
    psaLineItems: number[] = [];
    mmdLineItems: number[] = [];
    isBoth: boolean = false;
    currentLineMumbers: number[] = [];
    isShowDraftMessage: boolean = false;
    existingRequestLineIds: string[] = [];
    mmsKeys: string[] = [];
    toBeDeletedKeys: string[] = [];
    deletedKeys: string[] = [];
    excelIds: number[] = [];
    excelFilePath: string = 'RequestorAddItems';
    addItemsExcelColumnGOBU: any = AddItemsExcelColumnGOMBU;
    constructor(private addNewActionService: AddNewActionService,
        private router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService,
        private materialTypeService: MaterialTypeService,
        private qaCodeService: QACodeService,
        private unitOfMeasureService: UnitOfMeasureService,
        private materialGroupCodeService: MaterialGroupCodeService,
        private materialStatusService: MaterialStatusService,
        private segmentService: SegmentService,
        private storageConditionService: StorageCondtionService,
        private extensionMRPUpdateService: ExtensionMRPUpdateService,
        private spkService: SPKService,
        private dialogService: DialogService,
        private addNewItemService: AddNewItemService,
        private supplierService: SupplierService,
        private buService: BussinessUnitService,
        private requestIssueService: RequestIssueService,
        private tempActionService: TempActionService,
        private sarUsersService: SarUsersService,
        private userGroupsService: UserGroupsService,
        public requestLineService: RequestLineService,
        public approvalDetailService: ApprovalDetailService,
        private equipmentDataService: EquipmentDataService
    ) {
        this.excelFilePath = this.excelFilePath + this.commonService.getUserBUName();
        this.requestAddNewActionItemDetails = new AddNewItemActionResponseDto();
    }
    ngOnInit(): void {
        this.buName = this.commonService.getUserBUName();
        this.requestIssueId = this.requiredParams.requestIssueId!;
        if (!this.requiredParams.isNew) {
            this.isShowDraftMessage = true;
            this.getItems();
        }
        this.getItemFieldsData();
        this.subscription = this.equipmentDataService.data$.subscribe(data => {
            this.data = data;
        });
    }
    getItems() {
        if (this.commonService.isNullOrEmptyOrUndefined(this.requiredParams.requestId) ||
            this.commonService.isNullOrEmptyOrUndefined(this.requiredParams.requestIssueId)) {
            return false;
        }
        const getAddNewItemActionDetailsAPI = this.addNewActionService.getAddNewItemActionDetails(this.requiredParams.requestId!, this.requestIssueId);
        const getBUAllAPI = this.buService.getBUAll();
        const getRequestIssueTypesAPI = this.requestIssueService.getRequestIssueTypes(false);
        forkJoin([getAddNewItemActionDetailsAPI, getBUAllAPI, getRequestIssueTypesAPI]).
            subscribe(([getAddNewItemActionDetailsAPIData, getBUAllAPIData, getRequestIssueTypesAPIData]) => {
                if (getAddNewItemActionDetailsAPIData.items == null || (getAddNewItemActionDetailsAPIData.items != null && getAddNewItemActionDetailsAPIData!.items!.length == 0)) {
                    this.requestAddNewActionItemDetails = new AddNewItemActionResponseDto();
                    const path = `false/false/${this.requiredParams.requestId}`
                    this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
                        state: { requestId: this.requiredParams.requestId }, relativeTo: this.route.parent
                    });
                    return false;
                }
                this.requestAddNewActionItemDetails = getAddNewItemActionDetailsAPIData;

                this.attachmentDetails = getAddNewItemActionDetailsAPIData.attachments;
                this.attachmentDetailsDisplay = getAddNewItemActionDetailsAPIData.attachments ? getAddNewItemActionDetailsAPIData.attachments : [];
                let requestLineIdList = this.requestAddNewActionItemDetails.items.map(x => { return x.requestLineId });
                let deleteLineItem: DeleteLineItemDto = {
                    requestIssueId: this.requiredParams.requestIssueId!,
                    requestLineIds: requestLineIdList
                };
                this.cancelEvent.emit(deleteLineItem);
                let draftstotal = getAddNewItemActionDetailsAPIData.items.filter((item) => item.status!.toLocaleLowerCase() === 'draft').length;
                this.setBuId(getBUAllAPIData);
                if (draftstotal !== 0) {
                    this.isDraftavailable = true;
                    if (getAddNewItemActionDetailsAPIData.items.length === draftstotal && this.isShowDraftMessage) {
                        this.commonService.showNotificationMessage(`Request  Action ${this.requiredParams.actionNumber?.toString()} have all line items are in Draft state and has not submitted for approval yet. Please submit for approval.`, 'info', 'center');
                    }
                    this.isDraftavailableEvent.emit(true);
                    if (this.requestAddNewActionItemDetails.assignmentDetails && this.requestAddNewActionItemDetails.assignmentDetails.comment) {
                        this.comment = this.requestAddNewActionItemDetails.assignmentDetails.comment;
                    }
                    this.setRequestIssueTypeId(getRequestIssueTypesAPIData);
                    this.settempActionRequest();
                    this.tempActionRequestEvent.emit(this.tempActionRequest);
                    this.setLineAssignment();
                    this.getAssignmentGroups();
                    this.lineAssignmentEvent.emit(this.lineAssignment);
                }
                this.isShowDraftMessage = false;
            });
    }
    setBuId(BUData: BusinessUnitDto[]) {
        let bu: BusinessUnitDto[] = BUData.filter(e => e.shortName === this.buName);
        if (bu.length > 0) {
            this.businessUnitId = bu[0].id;
        }
    }

    setRequestIssueTypeId(RequestIssueTypes: RequestIssueTypeDto[]) {
        let actionType: string = 'Add New Item';
        let requestIssueType: RequestIssueTypeDto[] = RequestIssueTypes.filter(e => e.label?.toLocaleLowerCase() === actionType.toLocaleLowerCase()) as RequestIssueTypeDto[];
        if (requestIssueType.length > 0) {
            this.requestIssueTypeId = requestIssueType[0].id as number;
        }
    }

    rejectLineItem(dataItem: AddNewItemAction, index: number) {
        this.commonService.showConfirmation('Delete', ApprovalDetailsConstants.deleteMessage, false)
            .subscribe((res: any) => {
                if (res.action === 'yes') {
                    let delItem = new DeleteLineItemDto();
                    delItem.requestIssueId = this.requestIssueId;
                    delItem.requestLineIds = [];
                    delItem.requestLineIds.push(dataItem.requestLineId);
                    this.deleteLineItem = delItem;
                    this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
                        this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'info', 'right');
                        this.getItems();
                    })
                }
            });
    }

    settempActionRequest() {
        this.tempActionRequest = new TempActionRequestDto();
        this.tempActionRequest.requestId = this.requiredParams.requestId;
        this.tempActionRequest.requestIssueId = this.requiredParams.requestIssueId;
        this.tempActionRequest.requestIssueTypeId = this.requestIssueTypeId;
        this.tempActionRequest.attachments = [];
        this.tempActionRequest.comment = this.comment;
        this.tempActionRequest.createdBy = this.commonService.getUserEmailId();
        this.tempActionRequest.modifiedBy = this.commonService.getUserEmailId();
    }
    setLineAssignment() {
        this.lineAssignment = new LineAssignmentDto();
        this.lineAssignment.requestId = this.requiredParams.requestId;
        this.lineAssignment.requestIssueId = this.requiredParams.requestIssueId;
        this.lineAssignment.requestIssueTypeId = this.requestIssueTypeId;
        this.lineAssignment.businessUnitId = this.businessUnitId;
        this.lineAssignment.assignedGroupId = 0;
        this.lineAssignment.assignedUserId = 0;
        this.lineAssignment.state = 0;
        this.lineAssignment.isGeneralComment = false;
        this.lineAssignment.comment = this.comment;
        this.lineAssignment.createdBy = this.commonService.getUserEmailId();
        this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
        this.lineAssignment.attachments = [];
    }
    getItemFieldsData() {
        const materialType = this.materialTypeService.getMaterialType();
        const qACodes = this.qaCodeService.getQAAll();
        const uom = this.unitOfMeasureService.getUnitOfMeasure();
        const materialGroupCode = this.materialGroupCodeService.getMaterialGroupCode();
        const materialStatus = this.materialStatusService.getMaterialStatus();
        const segment = this.segmentService.getsegments();
        const storageConditionList = this.storageConditionService.getStorageConditionAll();
        const BUName = this.commonService.getUserBUName();
        const plantList = this.extensionMRPUpdateService.getPlantDetail(BUName);
        const spkList = this.spkService.getSPKAll();
        const supplier = this.supplierService.getAllSupplier();
        combineLatest([materialType, qACodes, uom, materialGroupCode, materialStatus, segment, storageConditionList, plantList, spkList, supplier])
            .subscribe(results => {
                this.materialTypes = results[0];
                this.qaCodeList = results[1];
                this.uomList = results[2];
                this.materialGroupCodeList = results[3];
                this.materialStatusList = results[4];
                this.segmentList = Object.assign([], results[5]);
                this.storageConditionList = results[6];
                this.plantList = Object.assign([], results[7]);
                this.SPKList = Object.assign([], results[8]);
                this.supplierList = Object.assign([], results[9]);
            })
    }

    navigateToAddNewItem() {
        this.router.navigate(["../" + requestIssueConst.addNewItem], {
            state: {
                isNew: true,
                requestId: this.requiredParams.requestId,
                requestIssueId: this.requiredParams.requestIssueId,
                requestNumber: this.requiredParams.requestNumber,
                actionNumber: this.requiredParams.actionNumber,
                changeType: this.requiredParams.changeType,
                lineItem: 0,
                requestLineId: 0,
                isDataExist: this.requestAddNewActionItemDetails!.items.length > 0,
                equipmentCriticality: this.data.equipmentCriticality,
                itemPartRanking: this.data.itemPartRanking,
                equipmentTags: this.data.equipmentTags
            },
            relativeTo: this.route
        },
        );
    }

    editLineItem(dataItem: AddNewItemAction, rowIndex: number) {
        this.router.navigate(["../" + 'updateaddnewitem'], {
            state: {
                isNew: false,
                requestId: this.requiredParams.requestId,
                requestIssueId: this.requiredParams.requestIssueId,
                requestNumber: this.requiredParams.requestNumber,
                actionNumber: this.requiredParams.actionNumber,
                changeType: this.requiredParams.changeType,
                lineItem: rowIndex + 1,
                requestLineId: dataItem.requestLineId,
                equipmentCriticality: dataItem?.equipmentCriticality,
                itemStatus: dataItem.status, buId: this.businessUnitId
            },
            relativeTo: this.route
        },
        );
    }

    cloneLineItem(dataItem: AddNewItemAction, rowIndex: number): void {
        const cloned = this.requestAddNewActionItemDetails?.items.find(i => i.id === dataItem.id)
        if (cloned)
            this.addNewItemService.cloneActionItem(cloned.requestLineId).subscribe((item: AddNewItemAction) => {
                this.getItems();
                this.commonService.showNotificationMessage(AddNewItemConstant.successMessage, 'success', 'right');
            });
    }

    public import(): void {
        this.existingRequestLineIds = [];
        this.requestAddNewActionItemDetails!.items.forEach(item => {
            item.mmsKey = [];
            if (item!.addNewItemActionDetails!.storageLocations!.length > 0) {
                item!.addNewItemActionDetails!.storageLocations!.forEach(slo => {
                    this.existingRequestLineIds.push(item.requestLineId + '-' + slo.id);
                    item.mmsKey?.push(item.requestLineId + '-' + slo.id);
                })
            }
            else {
                this.existingRequestLineIds.push(item.requestLineId + '-' + 0)
                item.mmsKey.push(item.requestLineId + '-' + 0);
            }
            if (item!.addNewItemActionDetails!.qaCodes!.length > 0) {
                item!.addNewItemActionDetails!.qaCodes!.forEach(code =>
                    item!.addNewItemActionDetails.qaCodeList?.push(code.qaCodeId))
            }
        })

        const dialog: DialogRef = this.dialogService.open({
            content: DialogUploadComponent
        });
        dialog.result.subscribe((r: any) => {
            this.excelIds = [];
            if (r.action === "Save" && r.isEdit) {
                this.editExcelData(r);
            }
            else if (r.action === "Save" && !r.isEdit) {
                this.addExcelData(r);
            }
        });
    }

    addExcelData(r: any) {
        if (r.action === "Save") {
            this.actionItems = [];
            this.countErrors = 0;
            this.uploadErrors = '';
            this.duplicatePlant_StorageLocation = false;
            let arr = JSON.parse(r.value).Item_List as Array<any>;
            let filteredArr = arr.filter(i => Object.keys(i).length > 1);
            filteredArr = filteredArr.slice(1); //remove header data
            if (filteredArr.length === 0) {
                this.commonService.showNotificationMessage(AddNewItemConstant.excelvalidations.noRows, 'warning', 'center');
            }
            else {
                let index = -1;
                for (let row of filteredArr) {
                    index++;
                    const item = this.setAddNewItemActionDetails(row, index, false);
                    let indexItem = this.ItemIndex(item);
                    if (indexItem > -1) {
                        let storageLocation = new StorageLocationDto();
                        if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                            let plantIndex = this.plantList.findIndex((e: PlantDetailsDto) => e.plantName === this.getPlantCode(row['__EMPTY_20']));
                            if (plantIndex > -1) {
                                storageLocation = this.setStorageLocation(row, index, plantIndex, false, item.unitPrice);
                                if (this.isAvailablePlantStorageCombo(indexItem, storageLocation.plantName!, storageLocation.storageLocatinName)) {
                                    this.duplicatePlant_StorageLocation = true;
                                }
                                else {
                                    this.actionItems[indexItem].addNewActionItemStorageLocation!.push(storageLocation);
                                }
                            }
                            else {
                                this.updateUploadError(index, AddNewItemConstant.excelvalidations.plantValidation);
                                if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buStorageLocationValidation);
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buPlantStorageValidation);
                                }
                            }
                        }
                    }
                    else {
                        item.addNewActionItemStorageLocation = [];
                        let storageLocation: StorageLocationDto;
                        if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                            let plantIndex = this.plantList.findIndex((e: PlantDetailsDto) => e.plantName === this.getPlantCode(row['__EMPTY_20']));
                            if (plantIndex > -1) {
                                storageLocation = this.setStorageLocation(row, index, plantIndex, false, item.unitPrice);
                                item.addNewActionItemStorageLocation.push(storageLocation);
                            }
                            else {
                                this.updateUploadError(index, AddNewItemConstant.excelvalidations.plantValidation);
                                if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buStorageLocationValidation);
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buPlantStorageValidation);
                                }
                            }
                        }
                        item.createdBy = this.commonService.getUserEmailId();
                        item.modifiedBy = this.commonService.getUserEmailId();
                        this.actionItems.push(item);
                    }
                }

                if (this.countErrors !== 0) {
                    let errormessage: string = '';
                    if (this.countErrors > 8) {
                        errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
                    }
                    else {
                        errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
                    }
                    errormessage += this.uploadErrors;
                    this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
                }
                else if (this.duplicatePlant_StorageLocation) {
                    this.commonService.showNotificationMessage(AddNewItemConstant.excelvalidations.plantAndStorageLocationDuplication, 'error', 'center');
                }
                else {
                    this.addNewItemService.importAddNewItemAction(this.actionItems).subscribe((response: any) => {
                        this.commonService.showNotificationMessage(AddNewItemConstant.successMessage, 'success', 'right');
                        this.requestIssueId = response.requestIssueId;
                        this.requiredParams.requestIssueId = response.requestIssueId;
                        this.requiredParams.isNew = false;
                        this.getItems();
                        let sarnewaction = JSON.parse(sessionStorage.getItem('sarnewaction')!);
                        sarnewaction.isNew = false;
                        sarnewaction.changeType = 'Add New Item';
                        sarnewaction.requestIssueId = response.requestIssueId;
                        sessionStorage.setItem('sarnewaction', JSON.stringify(sarnewaction));
                    });
                }
            }
        }
    }

    editExcelData(r: any) {
        if (r.action === "Save") {
            this.actionItems = [];
            this.countErrors = 0;
            this.uploadErrors = '';
            this.duplicatePlant_StorageLocation = false;
            let arr = JSON.parse(r.value).Item_List as Array<any>;
            let filteredArr = arr.filter(i => Object.keys(i).length > 1);
            filteredArr = filteredArr.slice(1); //remove header data
            if (filteredArr.length === 0) {
                this.commonService.showNotificationMessage(AddNewItemConstant.excelvalidations.noRows, 'warning', 'center');
            }
            else {
                let index = -1;
                this.mmsKeys = [];
                this.toBeDeletedKeys = Object.assign([], this.existingRequestLineIds);
                filteredArr.forEach(element => {
                    if (element['Add New Item SAR']) {
                        this.mmsKeys.push(element['Add New Item SAR']);
                        const key = element['Add New Item SAR'];
                        // const index = this.deletedKeys.indexOf(key);
                        if (key && this.existingRequestLineIds.includes(key)) {
                            this.toBeDeletedKeys = this.toBeDeletedKeys.filter(k => k != key);
                        }
                    }
                });


                for (let row of filteredArr) {
                    index++;
                    const item = this.setAddNewItemActionDetails(row, index, true);
                    let indexItem = this.ItemIndex(item);
                    if (indexItem > -1) {
                        let storageLocation = new StorageLocationDto();
                        if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                            let plantIndex = this.plantList.findIndex((e: PlantDetailsDto) => e.plantName === this.getPlantCode(row['__EMPTY_20']));
                            if (plantIndex > -1) {
                                storageLocation = this.setStorageLocation(row, index, plantIndex, true, item.unitPrice);
                                if (this.isAvailablePlantStorageCombo(indexItem, storageLocation.plantName!, storageLocation.storageLocatinName)) {
                                    this.duplicatePlant_StorageLocation = true;
                                }
                                else {
                                    this.actionItems[indexItem].addNewActionItemStorageLocation!.push(storageLocation);
                                }
                            }
                            else {
                                this.updateUploadError(index, AddNewItemConstant.excelvalidations.plantValidation);
                                if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buStorageLocationValidation);
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buPlantStorageValidation);
                                }
                            }
                        }
                    }
                    else {
                        item.addNewActionItemStorageLocation = [];
                        let storageLocation: StorageLocationDto;
                        if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                            let plantIndex = this.plantList.findIndex((e: PlantDetailsDto) => e.plantName === this.getPlantCode(row['__EMPTY_20']));
                            if (plantIndex > -1) {
                                storageLocation = this.setStorageLocation(row, index, plantIndex, true, item.unitPrice);
                                item.addNewActionItemStorageLocation.push(storageLocation);
                            }
                            else {
                                this.updateUploadError(index, AddNewItemConstant.excelvalidations.plantValidation);
                                if (!this.commonService.isNullOrEmptyOrUndefined(row['__EMPTY_20'])) {
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buStorageLocationValidation);
                                    this.updateUploadError(index, AddNewItemConstant.excelvalidations.buPlantStorageValidation);
                                }
                            }
                        }
                        item.createdBy = this.commonService.getUserEmailId();
                        item.modifiedBy = this.commonService.getUserEmailId();
                        this.actionItems.push(item);
                    }
                }

                if (this.countErrors !== 0) {
                    let errormessage: string = '';
                    if (this.countErrors > 8) {
                        errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
                    }
                    else {
                        errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
                    }
                    errormessage += this.uploadErrors;
                    this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
                }
                else if (this.duplicatePlant_StorageLocation) {
                    this.commonService.showNotificationMessage(AddNewItemConstant.excelvalidations.plantAndStorageLocationDuplication, 'error', 'center');
                }
                else {
                    if (this.toBeDeletedKeys.length > 0) {
                        //logic to add existing objects with operation code as needed for update and delete
                        this.requestAddNewActionItemDetails!.items.forEach(ele => {
                            const element = JSON.parse(JSON.stringify(ele));
                            let newItem = new AddNewItemDto();
                            element.mmsKey?.forEach((mmsKey: string) => {
                                // const mmsKey = <string>element!.mmsKey;
                                if (this.toBeDeletedKeys.includes(mmsKey)) {
                                    this.deletedKeys.push(mmsKey);
                                    this.toBeDeletedKeys = this.toBeDeletedKeys.filter(k => k != mmsKey);
                                    let getSloId = mmsKey.split("-")[1];
                                    let sloId = isNaN(parseInt(getSloId)) ? 0 : parseInt(getSloId);
                                    newItem.operation = this.excelIds.includes(<number>element.id) ? 2 : 3;
                                    newItem.id = element.id;
                                    newItem.requestId = this.requiredParams.requestId;
                                    newItem.requestIssueTypeId = this.requestIssueTypeId;
                                    newItem.lineNumber = element.lineNumber;
                                    newItem.manufacturerPartNumber = element.manufacturePartNumber;
                                    newItem.manufacturer = element.manufactureName;
                                    newItem.description = <string>element.description;
                                    newItem.unitOfMeasureId = element.unitOfMeasureId;
                                    newItem.materialTypeId = element.materialTypeId;
                                    newItem.materialGroupCodeId = element.materialGroupCodeId;;
                                    newItem.materialStatusId = element.materialStatusId;
                                    newItem.sizeOrDimensions = element.addNewItemActionDetails.dimensions;
                                    newItem.grossWeight = element.addNewItemActionDetails.grossWeight;
                                    newItem.storageConditionId = element.storageConditionId;
                                    newItem.isHazardous = element.addNewItemActionDetails.isHazardous;
                                    newItem.isCritical = element.addNewItemActionDetails.isCritical;
                                    newItem.isMRp = element.addNewItemActionDetails.isMRp;
                                    newItem.unitPrice = element.addNewItemActionDetails.unitPrice;
                                    newItem.leadTime = element.addNewItemActionDetails.leadTime;
                                    newItem.supplierPurchasing = element.addNewItemActionDetails.supplierPurchasing;
                                    newItem.supplierPartNumber = element.addNewItemActionDetails.supplierPartNumber;
                                    newItem.requestorNotes = element.requesterNotes;
                                    newItem.segmentId = element.segmentId;
                                    newItem.supplierPurchasingId = element.recommendedSupplierId;
                                    newItem.createdBy = element.createdBy;
                                    newItem.modifiedBy = this.commonService.getUserEmailId();
                                    newItem.addNewItemActionQACodes = [];
                                    newItem.addNewActionItemStorageLocation = [];

                                    // element.addNewItemActionDetails.qaCodes?.forEach( qaCode => {
                                    //   const qaCodeObj = new RequestLineQACode();
                                    //   qaCodeObj.id = qaCode.id;
                                    //   qaCodeObj.operation = element.addNewItemActionDetails.qaCodeList?.includes(qaCode.qaCodeId) ? 3 : 2;
                                    //   qaCodeObj.qaCodeId = qaCode.qaCodeId;
                                    //   qaCodeObj.requestLineId = qaCode.requestLineId;
                                    // newItem.addNewItemActionQACodes?.push(qaCodeObj);
                                    // })

                                    let sloArr = JSON.parse(JSON.stringify(element.addNewItemActionDetails.storageLocations!.filter((slo: any) => slo.id === sloId)));
                                    //console.log(sloArr);
                                    let newSLoItem = new AddNewItemDto();
                                    if (sloArr.length > 0) {
                                        const sloObj = new StorageLocationDto();
                                        sloObj.id = sloArr[0].id;
                                        sloObj.maximum = sloArr[0].maximum;
                                        sloObj.minimum = sloArr[0].minimum;
                                        sloObj.plantName = sloArr[0].plantName;
                                        sloObj.storageLocatinName = sloArr[0].storageLocatinName;
                                        sloObj.spkId = parseInt(<string>sloArr[0].specialProcurementKey);
                                        sloObj.estimatedInventoryValue = sloArr[0].estimatedInventoryValue;
                                        sloObj.operation = 3;
                                        sloObj.createdBy = this.commonService.getUserEmailId();
                                        newItem.addNewActionItemStorageLocation?.push(sloObj);
                                        newSLoItem = Object.assign({}, newItem)
                                    }

                                    newItem.addNewActionItemStorageLocation!.length > 0 ? this.actionItems.push(newSLoItem) : this.actionItems.push(newItem);
                                }


                            })

                        })
                    }
                    this.addNewItemService.importAddNewItemAction(this.actionItems).subscribe((response: any) => {
                        this.commonService.showNotificationMessage(AddNewItemConstant.successMessage, 'success', 'right');
                        this.requestIssueId = response.requestIssueId;
                        this.requiredParams.requestIssueId = response.requestIssueId;
                        this.requiredParams.isNew = false;
                        this.getItems();
                        this.deletedKeys = [];
                        this.toBeDeletedKeys = [];
                        let sarnewaction = JSON.parse(sessionStorage.getItem('sarnewaction')!);
                        sarnewaction.isNew = false;
                        sarnewaction.changeType = 'Add New Item';
                        sarnewaction.requestIssueId = response.requestIssueId;
                        sessionStorage.setItem('sarnewaction', JSON.stringify(sarnewaction));
                    });
                }
            }
        };

    }


    setAddNewItemActionDetails(addNewActionItem: any, index: number, isEdit: boolean): AddNewItemDto {
        const mmsKey = addNewActionItem['Add New Item SAR'] ? addNewActionItem['Add New Item SAR'] : '';
        const getId = mmsKey.split("-")[0];
        const id = getId ? parseInt(getId) : 0;
        let item = new AddNewItemDto();
        isNaN(id) ? "" : this.excelIds.includes(id) ? "" : this.excelIds.push(id);
        item.id = isEdit === true ? isNaN(id) ? 0 : id : 0;
        item.requestId = this.requiredParams.requestId;
        item.requestIssueTypeId = 3;
        item.requestIssueId = this.requiredParams.isNew ? 0 : this.requiredParams.requestIssueId;

        item.operation = isEdit == false ? 1 : this.existingRequestLineIds.includes(mmsKey) ? 2 : 1;
        item.lineNumber = this.commonService.isNAN(addNewActionItem['__EMPTY']) ? 0 : parseInt(addNewActionItem['__EMPTY']);
        if (this.isValid(addNewActionItem, '__EMPTY_1', index, AddNewItemConstant.excelvalidations.invalidDescription)) {
            item.description = addNewActionItem['__EMPTY_1'].toString();
        }
        item.manufacturer = this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_2']) ? '' : addNewActionItem['__EMPTY_2'].toString();
        item.manufacturerPartNumber = this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_3']) ? '' : addNewActionItem['__EMPTY_3'].toString();
        if (this.isValid(addNewActionItem, '__EMPTY_4', index, AddNewItemConstant.excelvalidations.materialTypeValidation)) {
            item.materialTypeId = this.materialTypes.filter((matType: MaterialType) => matType.code === this.getCode(addNewActionItem['__EMPTY_4']))[0].id;
        }
        if (this.isValid(addNewActionItem, '__EMPTY_5', index, AddNewItemConstant.excelvalidations.umValidation)) {
            item.unitOfMeasureId = this.uomList.filter((uom: UnitOfMeasure) => uom.code === this.getCode(addNewActionItem['__EMPTY_5']))[0].id;
        }
        if (this.isValid(addNewActionItem, '__EMPTY_6', index, AddNewItemConstant.excelvalidations.materialGroupCodeValidation)) {
            item.materialGroupCodeId = this.materialGroupCodeList.filter((matCode: MaterialGroupCode) => matCode.code === this.getCode(addNewActionItem['__EMPTY_6']))[0].id;
        }
        if (this.buName === 'GOMBU') {
            if (this.isValid(addNewActionItem, '__EMPTY_7', index, AddNewItemConstant.excelvalidations.segmentValidation)) {
                item.segmentId = this.segmentList.filter((seg: SegmentDto) => seg.name === addNewActionItem['__EMPTY_7'].toString().trim())[0].id;
            }
        }
        else {
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_7'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.bu_segmentValidation);
            }
        }
        item.grossWeight = this.commonService.isNAN(addNewActionItem['__EMPTY_8']) ? 0 : parseInt(addNewActionItem['__EMPTY_8']);
        item.sizeOrDimensions = this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_9']) ? '' : addNewActionItem['__EMPTY_9'].toString();
        if (this.buName === 'GOMBU') {
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_10'])) {
                item.materialStatusId = this.materialStatusList.filter((matStat: MaterialStatus) => matStat.code === this.getCode(addNewActionItem['__EMPTY_10']))[0].id;
            }
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_11'])) {
                item.storageConditionId = this.storageConditionList.filter((strCond: StorageConditionDto) => strCond.code === this.getCode(addNewActionItem['__EMPTY_11']))[0].id;
            }
            item.isHazardous = (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_12']) && addNewActionItem['__EMPTY_12'].toLocaleLowerCase() === 'yes') ? true : false;
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_13']) && addNewActionItem['__EMPTY_13'].toLocaleLowerCase() === 'yes') {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.criticalValidation);
            }
            else {
                item.isCritical = false;
            }
        }
        else {
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_10'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.materialStatusValidation);
            }
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_11'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.storageConditionValidation);
            }
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_12']) && addNewActionItem['__EMPTY_12'].toLocaleLowerCase() === 'yes') {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.hazardousValidation);
            }
            else {
                item.isHazardous = false;
            }
            item.isCritical = (!this.commonService.isEmptyOrUndefined(addNewActionItem['__EMPTY_13']) && addNewActionItem['__EMPTY_13'].toLocaleLowerCase() === 'yes') ? true : false;
        }
        if (this.isValid(addNewActionItem, '__EMPTY_14', index, AddNewItemConstant.excelvalidations.mrpValidation)) {
            item.isMRp = addNewActionItem['__EMPTY_14'].toLocaleLowerCase() === 'yes' ? true : false;
        }
        if (this.commonService.isNAN(addNewActionItem['__EMPTY_15'])) {
            this.updateUploadError(index, AddNewItemConstant.excelvalidations.unitPriceValidation);
        }
        else {
            item.unitPrice = addNewActionItem['__EMPTY_15'];
        }
        if (this.commonService.isNAN(addNewActionItem['__EMPTY_16'])) {
            this.updateUploadError(index, AddNewItemConstant.excelvalidations.leadTimeValidation);
        }
        else {
            item.leadTime = addNewActionItem['__EMPTY_16'];
        }
        item.addNewItemActionQACodes = [];
        if (this.buName !== 'GOMBU') {
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_17'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.qaCodeForGOMBUValidation);
            }
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_18'])) {
                let supplierNumber = addNewActionItem['__EMPTY_18'].toString().split(':')[0].trim();
                let supplier = this.supplierList.filter((supplier: SupplierDto) => supplier.supplierNumber === supplierNumber);
                if (supplier.length > 0) {
                    item.supplierPurchasing = supplier[0].supplierName;
                    item.supplierPurchasingId = supplier[0].id;
                }
            }
            item.supplierPartNumber = this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_19']) ? '' : addNewActionItem['__EMPTY_19'].toString();
        }
        else {
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_17'])) {
                let qaCodes: any = [];
                if (addNewActionItem['__EMPTY_17'].toString().includes(',')) {
                    qaCodes = addNewActionItem['__EMPTY_17'].split(',').map((e: string) => e.trim());
                }
                else {
                    qaCodes.push(addNewActionItem['__EMPTY_17'].toString());
                }
                //let qaCodes: string[] = addNewActionItem['__EMPTY_17'].toString().includes(',') ? addNewActionItem['__EMPTY_17'].split(',').map((e: string) => e.trim()) : addNewActionItem['__EMPTY_17'];
                qaCodes.forEach((qaCode: any) => {
                    let _qaCodeVar = this.qaCodeList.filter(e => e.code === qaCode);
                    if (_qaCodeVar.length === 0) {
                        this.updateUploadError(index, AddNewItemConstant.excelvalidations.qaCodeValidation);
                        return false;
                    }

                    //QA code changes
                    let QAcode: any;
                    let itemList = this.requestAddNewActionItemDetails!.items.filter((x: AddNewItemAction) => x.id === item.id);
                    if (itemList.length >= 1) {
                        QAcode = itemList[0].addNewItemActionDetails.qaCodes!.filter((x: any) => x.requestLineId == item.id && x.qaCode == qaCode);
                    }
                    let _qaCode = new RequestLineQACode();
                    _qaCode.id = QAcode ? (QAcode.length == 1 ? QAcode[0].id : 0) : 0;
                    _qaCode.qaCodeId = _qaCodeVar[0].id;
                    _qaCode.requestLineId = item.id ? item.id : 0;
                    _qaCode.operation = _qaCode.id == 0 ? 1 : 2;
                    _qaCode.createdBy = this.commonService.getUserEmailId();
                    _qaCode.modifiedBy = this.commonService.getUserEmailId();
                    item.addNewItemActionQACodes!.push(_qaCode);
                });
            }
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_18'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.supplierValidation);
            }
            if (!this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_19'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.supplierPartNumValidation);
            }
        }
        item.requestorNotes = this.commonService.isNullOrEmptyOrUndefined(addNewActionItem['__EMPTY_27']) ? '' : addNewActionItem['__EMPTY_27'].toString();
        return item;
    }
    setStorageLocation(item: any, index: number, plantIndex: number, isEdit: boolean, unitPrice?: number): StorageLocationDto {
        let isStorageLocationNew = true;
        let requestLineId = 0;
        let sloId = 0;
        if (item['Add New Item SAR'] != undefined) {
            requestLineId = item['Add New Item SAR'].split("-")[0];
            sloId = item['Add New Item SAR'].split("-")[1];
        }
        const mmsKey = item['Add New Item SAR'] ? item['Add New Item SAR'] : '';
        if (this.existingRequestLineIds.includes(mmsKey)) {
            isStorageLocationNew = false;
        }
        let storageLocation = new StorageLocationDto();
        storageLocation.id = isStorageLocationNew ? 0 : sloId;
        storageLocation.requestLineId = isEdit === false ? 0 : requestLineId;
        storageLocation.operation = isEdit === false ? 1 : sloId == 0 ? 1 : 2;
        storageLocation.plantName = this.plantList[plantIndex].plantName;
        storageLocation.plantDescription = this.plantList[plantIndex].plantDiscription;
        storageLocation.storageLocatinName = '';
        storageLocation.storageLocationDescription = '';
        if (!this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_21'])) {
            let strLocation = this.plantList[plantIndex].storageLocationDetails.filter((strLocation: StorageLocationDetailsDto) => strLocation.storageLocation === item['__EMPTY_21']);
            if (strLocation.length !== 0) {
                storageLocation.storageLocatinName = item['__EMPTY_21'];
                storageLocation.storageLocationDescription = strLocation[0].storageLocationDiscription;
            }
            else {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.buPlantStorageValidation);
            }
        }
        storageLocation.minimum = this.commonService.isNAN(item['__EMPTY_22']) ? 0 : parseInt(item['__EMPTY_22']);
        storageLocation.maximum = this.commonService.isNAN(item['__EMPTY_23']) ? 0 : parseInt(item['__EMPTY_23']);
        if (storageLocation.maximum! < storageLocation.minimum!) {
            this.updateUploadError(index, AddNewItemConstant.excelvalidations.MaxMinValidation);
        }
        storageLocation.roundingValue = this.commonService.isNAN(item['__EMPTY_24']) ? 0 : parseInt(item['__EMPTY_24']);
        //storageLocation.estimatedInventoryValue = this.commonService.isEmptyOrUndefined(item['__EMPTY_25']) ? 0 : item['__EMPTY_25'];
        storageLocation.estimatedInventoryValue = unitPrice ? ((storageLocation.maximum + storageLocation.roundingValue) * unitPrice) : (this.commonService.isNAN(item['__EMPTY_25']) ? 0 : item['__EMPTY_25']);
        if (this.buName === 'SJVBU') {
            if (!this.commonService.isEmptyOrUndefined(item['__EMPTY_26'])) {
                let spkCode = this.getCode(item['__EMPTY_26']);
                let slo: SPKDto[] = this.SPKList.filter((spk: SPKDto) => spk.code === spkCode);
                storageLocation.spkId = slo.length > 0 ? slo[0].id : undefined;
            }
        }
        else {
            if (!this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_26'])) {
                this.updateUploadError(index, AddNewItemConstant.excelvalidations.spkForSJVBUValidation);
            }
        }
        storageLocation.createdBy = this.commonService.getUserEmailId();
        storageLocation.modifiedBy = this.commonService.getUserEmailId();
        return storageLocation;
    }


    ItemIndex(item: any): number {
        const index: number = this.actionItems.findIndex(obj =>
            obj.description === item.description
            && obj.manufacturer === item.manufacturer
            && obj.manufacturerPartNumber === item.manufacturerPartNumber
            && obj.unitOfMeasureId === item.unitOfMeasureId
            && obj.materialTypeId === item.materialTypeId
            && obj.materialGroupCodeId === item.materialGroupCodeId
            && obj.sizeOrDimensions === item.sizeOrDimensions
            && obj.materialStatusId === item.materialStatusId
            && obj.grossWeight === item.grossWeight
            && obj.storageConditionId === item.storageConditionId
            && obj.isHazardous === item.isHazardous
            && obj.isCritical === item.isCritical
            && obj.isMRp === item.isMRp
            && obj.unitPrice === item.unitPrice
            && obj.leadTime === item.leadTime
            && obj.supplierPurchasing === item.supplierPurchasing
            && obj.supplierPartNumber === item.supplierPartNumber
            && obj.requestorNotes === item.requestorNotes
            && obj.segmentId === item.segmentId
        );
        return index;
    }
    getCode(st: string) {
        return st.split(':')[0].trim();
    }
    getPlantCode(st: string) {
        return st.split('-')[0].trim();
    }
    updateUploadError(rowIndex: number, message: string) {
        this.countErrors++;
        if (this.countErrors <= 8) {
            this.uploadErrors += `Row ${rowIndex + 3}: ${message}<br/>`
        }
    }
    isValid(lItem: any, itemTag: string, index: number, invalidErrorMessage: string): boolean {
        if (this.commonService.isNullOrEmptyOrUndefined(lItem[itemTag])) {
            this.updateUploadError(index, invalidErrorMessage);
            return false;
        }
        return true;
    }

    isAvailablePlantStorageCombo(indexItem: number, plantCode: string, storageCode?: string) {
        let index = this.actionItems[indexItem].addNewActionItemStorageLocation!.findIndex(item =>
            item.plantName === plantCode
            && item.storageLocatinName === storageCode)
        return index > -1 ? true : false;
    }

    onFilesUpload(event: any) {
        const files = event.target.files;
        const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
        if (files.length > 0) {
            if (this.isValidFile(files[0])) {
                const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
                this.tempActionRequest.attachments!.push(files[0]);
                this.lineAssignment.attachments?.push(files[0]);
                this.attachmentDetailsDisplay.push(newAttachment);
                this.fileInput.nativeElement.value = '';
                this.tempActionRequestEvent.emit(this.tempActionRequest);
                this.lineAssignmentEvent.emit(this.lineAssignment);
            }
            else {
                this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
            }
        }
    }
    isValidFile(file: File): boolean {
        const name: string = file.name;
        const extension: string = '.' + name.split('.')[1];
        return this.acceptedFiles.includes(extension.toLocaleLowerCase());
    }
    setNewAttachment(file: File): AddNewActionItemsAttachmentDetailsDisplay {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = new AddNewActionItemsAttachmentDetailsDisplay();
        newAttachment.attachmentId = 0;
        newAttachment.file = file;
        newAttachment.fileName = file.name;
        newAttachment.blobUrI = '';
        newAttachment.operation = 1;
        return newAttachment;
    }
    removeFile(index: number) {
        if (this.attachmentDetailsDisplay && this.attachmentDetailsDisplay[index]) {
            let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.attachmentDetailsDisplay[index];
            if (attachment.attachmentId !== 0) {
                this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
            }
            else {
                let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.attachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
                let attachmentsIndex: number = index - oldAttachments.length;
                this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
                this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
                this.tempActionRequestEvent.emit(this.tempActionRequest);
                this.lineAssignmentEvent.emit(this.lineAssignment);
            }
            this.attachmentDetailsDisplay!.splice(index, 1);

        }
    }
    downloadFile(index: number) {
        if (this.attachmentDetailsDisplay && this.attachmentDetailsDisplay[index]) {
            let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.attachmentDetailsDisplay[index];
            if (attachment.attachmentId !== 0) {
                this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
                    this.commonService.downloadFile(response, attachment.fileName!);
                });
            }
            else {
                const blob = new Blob([attachment.file!], { type: attachment.file!.type });
                this.commonService.downloadFile(blob, attachment.fileName!);
            }
        }
    }
    onChangeComment() {
        this.lineAssignment.comment = this.comment;
        this.tempActionRequest.comment = this.comment;
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
    }
    getAssignmentGroups() {
        let isMMD = false;
        let isSA = false;
        this.isBoth = false;
        let groupType = 1;
        this.psaLineItems = [];
        this.mmdLineItems = [];
        this.requestAddNewActionItemDetails!.items.forEach((element, index) => {
            if (element.status === 'Draft') {
                if (element.addNewItemActionDetails.storageLocations!.reduce((sum, current) => sum + current.maximum!, 0) > 0) {
                    isSA = true;
                    this.psaLineItems.push(element.lineNumber);
                }
                else {
                    isMMD = true;
                    this.mmdLineItems.push(element.lineNumber);
                }
            }
        })
        if (isSA && !isMMD) {
            groupType = 2
        }
        if (isSA && isMMD) {
            this.isBoth = true;
            this.tab('psa');
        }

        if (!this.isBoth) {
            this.userGroupsService.getAssignmentGroups(groupType).subscribe((data: any) => {
                this.assignToGroups = data;
            })
        }
    }
    tab(eventName: string) {
        this.lineAssignment.isMixedValues = true;
        this.lineAssignment.assignedGroupId = 0;
        this.lineAssignment.assignedUserId = 0;
        if (eventName === 'psa') {
            this.currentLineMumbers = this.psaLineItems;
        }
        else {
            this.currentLineMumbers = this.mmdLineItems;
        }
    }
    getAssignmentUsers() {
        if (this.lineAssignment.assignedGroupId) {
            this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
                this.assignToUsers = data;
            })
        }
    }
    selectAssignToGroup(group: Group) {
        this.lineAssignment.assignedGroupId = group.id;
        this.lineAssignment.assignedUserId = 0;
        this.selectedUser = 0;
        this.getAssignmentUsers();
        this.enabledAssignToUser = group.id ? true : false;
        this.lineAssignmentEvent.emit(this.lineAssignment);
    }
    selectAssignToUser(user: UserDto) {
        this.lineAssignment.assignedUserId = user.id;
        this.lineAssignmentEvent.emit(this.lineAssignment);
    }

    bulkCancel() {
        let fItems = this.requestAddNewActionItemDetails!.items
            .filter(({ requestLineId }) => this.selectedItems.includes(requestLineId));
        let draftCount = fItems.filter(x => x.status === 'Draft').length;
        let rejectCount = fItems.filter(x => x.status === 'Rejected').length;
        if (draftCount > 0 && rejectCount > 0) {
            this.commonService.showNotificationMessage(AddNewItemConstant.bulkActionError, 'error', 'right');
            return false;
        }
        const dialog: DialogRef = this.dialogService.open({
            content: BulkCancelComponent
        });
        const inc = dialog.content.instance as BulkCancelComponent;
        inc.isCancel = draftCount > 0 ? false : true;
        dialog.result.subscribe((r: any) => {
            if (this.commonService.isNullOrEmpty(r.action) || r.action === 'Cancel') {
                let i = 0;
                let mmd: MMDActionDto[] = [];
                for (let dataContext of this.selectedItems) {
                    let dataItem = this.requestAddNewActionItemDetails!.items.find(x => x.requestLineId === dataContext)
                    if (dataItem && (dataItem.status === 'Draft' || dataItem.status === 'Rejected')) {
                        mmd.push(this.getmmdAction(dataItem!, i, r.comment))
                    }
                    i++;
                }
                this.mmdBulkAction(mmd);
            }
            else if (this.commonService.isNullOrEmpty(r.action) || r.action === 'Delete') {
                let delItem = new DeleteLineItemDto();
                delItem.requestIssueId = this.requestIssueId;
                delItem.requestLineIds = [];
                delItem.requestLineIds = fItems.map(x => { return x.requestLineId })
                this.deleteLineItem = delItem;
                this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
                    this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'success', 'right');
                    this.getItems();
                })
            }
            else {
                return false;
            }
        });
    }
    cancelLineItems(dataItem: AddNewItemAction, index: number, comment: string) {
        this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
            .subscribe((res: any) => {
                if (res.action === 'yes') {
                    let mmd: MMDActionDto[] = [];
                    mmd.push(this.getmmdAction(dataItem, index, comment))
                    this.mmdBulkAction(mmd);
                }
            });
    }


    getmmdAction(dataItem: AddNewItemAction, index: number, comment: string) {
        let mmdAction: MMDActionDto = new MMDActionDto();
        mmdAction.comment = comment;
        mmdAction.createdBy = this.commonService.getUserEmailId();
        mmdAction.modifiedBy = this.commonService.getUserEmailId();
        mmdAction.requestIssueId = this.requestIssueId;
        mmdAction.requestIssueTypeId = this.requestIssueTypeId;
        mmdAction.requestLineId = dataItem.requestLineId;
        mmdAction.MMDAction = 3;
        mmdAction.requestId = this.requiredParams.requestId!
        mmdAction.isEditLineItem = false
        return mmdAction;
    }

    mmdBulkAction(mmdAction: MMDActionDto[]) {
        this.approvalDetailService.bulkmmdCancelAction(mmdAction).subscribe((response: any) => {
            this.commonService.showNotificationMessage(SharedConst.cancelLineItem, 'success', 'right');
            this.getItems();
        });
    }
    containsReject() {
        if (this.requestAddNewActionItemDetails!.items.findIndex(x => x.status === 'Rejected' || x.status === 'Draft') >= 0) {
            return false;
        }
        return true;
    }

    accessForEditAction(dataItem: AddNewItemAction) {
        if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
            return true;
        }
        else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
            return false;
        }
        else {
            if (this.commonService.getUserEmailId() === dataItem.createdBy) {
                return true;
            }
            return false;
        }
    }

    public rowCallback = (context: RowClassArgs) => {
        if (context.dataItem.status === 'Draft' && this.currentLineMumbers.includes(context.dataItem.lineNumber)) {
            return { border: true };
        } else {
            return { border: false };
        }
    }

    editAndDownload() {
        const zip = new JSZip();
        zip.file(this.requiredParams.requestNumber + ' ' + "AddItems_Raw_Data.xlsx", this.commonService.exportAsExcelFile(this.exportToExcel(), this.addItemsExcelColumnGOBU,
            'AddItems_Raw_Data', true)
        );
        this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {
            this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
                const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
                zip.file('AddItemsTemplate_' + this.commonService.getUserBUName() + '_edit.xlsx', blobdownload)
                this.tempActionService.fetchBlobUrl(BlobURlsDetails.requestorAddItemsUserGuide).subscribe((responseUrl: string) => {
                    this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
                        const blobdownload = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
                        zip.file(BlobURlsDetails.requestorAddItemsUserGuide + '.docx', blobdownload);
                        zip.generateAsync({ type: "blob" }).then((content) => {
                            saveAs(content, this.requiredParams.requestNumber + ' ' + "AddItems_edit.zip");
                        });
                    })
                })
            })
        });
    }

    public exportToExcel(): any {
        let finalAddNewItemList: AddNewItemAction[] = [];
        let tempAddNewItem: AddNewItemAction[] = JSON.parse(JSON.stringify(this.requestAddNewActionItemDetails!.items.filter((x) => x.status === 'Draft')));

        for (let tempDataObj of tempAddNewItem) {
            let tempSLObj = JSON.parse(JSON.stringify(tempDataObj));
            tempSLObj.addNewItemActionDetails.qaCodes = [];

            tempDataObj.addNewItemActionDetails.qaCodes?.forEach((qaCodes: any) => {
                tempSLObj.addNewItemActionDetails.qaCodes.push(qaCodes);
            })

            tempDataObj.addNewItemActionDetails.storageLocations?.forEach((slValue: any) => {
                tempSLObj.addNewItemActionDetails.storageLocations = [];
                tempSLObj.addNewItemActionDetails.storageLocations.push(slValue);
                finalAddNewItemList.push(JSON.parse(JSON.stringify(tempSLObj)));
            })
            if (tempDataObj.addNewItemActionDetails.storageLocations!.length <= 0) {
                finalAddNewItemList.push(JSON.parse(JSON.stringify(tempSLObj)));
            }
        }
        let convertedArr = [];
        let index = 0;
        for (let itemList of finalAddNewItemList) {
            index = itemList.addNewItemActionDetails.storageLocations!.filter((x: any) => x.lineNumber === itemList.lineNumber).length;
            index = index > 0 ? index : 0;
            let storageLocationId = itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].id : 0;
            let obj = {
                mmsKey: itemList.requestLineId + '-' + storageLocationId,
                lineNumber: itemList.lineNumber,
                description: itemList.description,
                manufactureName: itemList.manufactureName,
                manufacturePartNumber: itemList.manufacturePartNumber,
                materialTypeName: itemList.materialTypeName,
                unitOfMeasureName: itemList.unitOfMeasureName,
                equipmentCriticality: itemList.equipmentCriticality,
                materialGroupCodeName: itemList.materialGroupCodeName,
                segmentName: itemList.segmentName,
                grossWeight: itemList.addNewItemActionDetails.grossWeight,
                dimensions: itemList.addNewItemActionDetails.dimensions,
                materialStatusName: itemList.addNewItemActionDetails.materialStatusName,
                storageConditioName: itemList.addNewItemActionDetails.storageConditioName,
                isHazardous: itemList.addNewItemActionDetails.isHazardous === true ? 'Yes' : 'No',
                isCritical: itemList.addNewItemActionDetails.isCritical === true ? 'Yes' : 'No',
                isMRp: itemList.addNewItemActionDetails.isMRp === true ? 'Yes' : 'No',
                unitPrice: itemList.addNewItemActionDetails.unitPrice,
                leadTime: itemList.addNewItemActionDetails.leadTime,
                qaCode: itemList.addNewItemActionDetails.qaCodes!.length > 0 ? itemList.addNewItemActionDetails.qaCodes?.map((x: any) => { return x.qaCode }).toString() : '',
                supplierPurchasing: itemList.addNewItemActionDetails.supplierPurchasing,
                supplierPartNumber: itemList.addNewItemActionDetails.supplierPartNumber,
                plantName: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].plantName : '',
                storageLocatinName: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].storageLocatinName : '',
                minimum: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].minimum : '',
                maximum: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].maximum : '',
                roundingValue: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].roundingValue : '',
                estimatedInventoryValue: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].estimatedInventoryValue : '',
                specialProcurementKey: itemList.addNewItemActionDetails.storageLocations!.length > 0 ? itemList.addNewItemActionDetails.storageLocations![index].specialProcurementKey : '',
                comment: itemList.comment,
                materialNumber: itemList.materialNumber,
                shortDescription: itemList.shortDescription,
                enrichedDescription: itemList.enrichedDescription,
            }
            convertedArr.push(obj)
        }
        return convertedArr;
    }

    downloadTemplate() {
        this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {
            this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
                const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(blobdownload, this.requiredParams.requestNumber + ' ' + 'AddItemsTemplate_' + this.commonService.getUserBUName() + '.xlsx');
            })
        });
    }
}