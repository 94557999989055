import { ActivatedRoute, Router } from '@angular/router';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeleteLineItemDto } from '../../models/common/delete-line-item.model';
import { EquipmentDataService } from '../../models/common/equipment-data.service';
import { EquipmentDto } from '../../models/common/equipment.model';
import { LineAssignmentDto } from '../../models/common/line-assignment.model';
import { NewActionInputParam } from '../../models/common/new-action-input-param.model';
import { of } from 'rxjs';
import { requestConst } from 'src/app/core/constants/request.const';
import { RequestLineService } from '../../services/request-line.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { switchMap } from 'rxjs/operators';
import { TempActionRequestDto } from '../../models/temp-actions/temp-action-request.module';
import { TempActionService } from '../../services/temp-action.service';

@Component({
  selector: 'app-sar-new-action',
  templateUrl: './sar-new-action.component.html',
  styleUrls: ['./sar-new-action.component.scss']
})
export class SarNewActionComponent implements OnInit, OnDestroy {
  isChangeSAR: boolean = false;
  isAddNewItem: boolean = false;
  equipmentSelect = false;
  selectedActionType: string = '0';
  selectedChangeType: string = '0';
  selectedEquipment: string;
  equipment: string[] = [];

  data: string[] = [];
  selectedEquipments: any[] = [];
  equipmentSelectPlaceHolder: string;
  equipmentCriticality = "-";
  itemPartRanking = "-";
  requestId: number = 0;
  requestNumber: number = 0;
  requestIssueId: number = 0;
  actionNumber: number = 0;
  action: string = '';
  isNew: boolean = false;
  newActionInputParam: NewActionInputParam = {};
  tempActionRequest: TempActionRequestDto = new TempActionRequestDto();
  lineAssignment?: LineAssignmentDto = new LineAssignmentDto();
  deleteLineItem: DeleteLineItemDto = new DeleteLineItemDto();
  isGoupSelected: boolean = true;
  isDraftavailable: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private tempActionService: TempActionService,
    private requestLineService: RequestLineService,
    private equipmentDataService: EquipmentDataService) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      sessionStorage.setItem('sarnewaction', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('sarnewaction')!);
      if (!this.commonService.isNullOrEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
      }
    }
  }

  ngOnInit() {
    if (this.newActionInputParam.changeType === 'Extension MRP Update' && this.isNew === false) {
      this.isChangeSAR = true;
      this.selectedActionType = '2';
      this.selectedChangeType = '2';
    }
    else if (this.newActionInputParam.changeType === 'Change Data' && this.isNew === false) {
      this.isChangeSAR = true;
      this.selectedActionType = '2';
      this.selectedChangeType = '1';
    }
    else if (this.newActionInputParam.changeType === 'Add New Item' && this.isNew === false) {
      this.isChangeSAR = false;
      this.isAddNewItem = true;
      this.equipmentSelect = true;
      this.selectedActionType = '1';
      this.selectedChangeType = '0';
    }
  }

  actionSubTypeChange(value: string) {
    this.newActionInputParam.changeType = 'Change Data';
    if (value === '2') {
      this.newActionInputParam.changeType = 'Extension MRP Update';
    }
  }

  onValueChange(value: string[]) {
    this.selectedEquipments = value;
    let formattedArray = this.selectedEquipments[0].split(", ").map((item: string) => item.trim());
    let equipment: EquipmentDto = {
      itemPartRanking: '',
      equipmentCriticality: '',
      equipmentTags: []
    };

    this.requestLineService.getEquipmentProperties(formattedArray)
      .subscribe(response => { 
        this.equipmentCriticality = response.criticality;
        this.itemPartRanking = response.partRanking;
        equipment.equipmentCriticality = this.equipmentCriticality;
        equipment.itemPartRanking = this.itemPartRanking;       
      }
    );

    equipment.equipmentTags = formattedArray;
    this.equipmentDataService.setData(equipment);
  }

  handleFilter(value: string) {
    this.data = this.equipment.filter(
      (s) => s?.toLowerCase().indexOf(value!.toLowerCase()) !== -1
    );
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('sarnewaction');
  }

  actionTypeChange(value: string) {
    this.selectedChangeType = '0';
    this.isChangeSAR = false;
    this.newActionInputParam.changeType = 'Add New Item';
    if (value === '1') {
      this.equipmentSelect = true;
    }
    if (value === '2') {
      this.equipmentSelect = false;
      this.isChangeSAR = true;
    }
  }

  public onFilterChange(searchTerm: string): void {
    if (searchTerm && searchTerm.trim() !== '') {
      this.data = [];
      this.requestLineService.getEquipment(searchTerm).pipe(
        switchMap(result => {
          return of(result);
        })
      ).subscribe(result => {
        this.data = result;
      });
    }
  }

  public onRemove(e: ChipRemoveEvent): void {
    const index = this.selectedEquipments
      .map((c) => c)
      .indexOf(e.sender.label);
    this.selectedEquipments.splice(index, 1);
  }

  navViewSAR() {
    const path = `false/false/${this.newActionInputParam.requestId}`;
    this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
      state: { isManage: false, isCreate: false, requestId: this.newActionInputParam.requestId }, relativeTo: this.route.parent
    });
  }

  saveDraft() {
    const formData = new FormData();
    formData.append('requestId', this.tempActionRequest.requestId!.toString());
    formData.append('requestIssueId', this.tempActionRequest.requestIssueId!.toString());
    formData.append('requestIssueTypeId', this.tempActionRequest.requestIssueTypeId!.toString());
    formData.append('comment', this.tempActionRequest.comment!);
    formData.append('createdBy', this.tempActionRequest.createdBy!);
    formData.append('modifiedBy', this.tempActionRequest.modifiedBy!);
    if (this.tempActionRequest.attachments!.length) {
      for (let attachment of this.tempActionRequest.attachments!) {
        formData.append('attachments', attachment);
      }
    }
    else {
      formData.append('attachments', '');
    }
    this.tempActionService.createTempAction(formData).subscribe((response: any) => {
      this.navViewSAR();
    })
  }

  saveAndExit() {
    if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!) && !this.lineAssignment?.isMixedValues) {
      this.isGoupSelected = false;
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
    }
    else {
      let userId: any = this.lineAssignment!.assignedUserId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedUserId!.toString())) {
        userId = null;
      }
      let groupId: any = this.lineAssignment!.assignedGroupId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!)) {
        groupId = null;
      }
      this.isGoupSelected = true;
      const formData = new FormData();
      formData.append('requestId', this.lineAssignment!.requestId!.toString());
      formData.append('requestIssueId', this.lineAssignment!.requestIssueId!.toString());
      formData.append('requestIssueTypeId', this.lineAssignment!.requestIssueTypeId!.toString());
      formData.append('businessUnitId', this.lineAssignment!.businessUnitId!.toString());
      formData.append('assignedGroupId', groupId);
      formData.append('assignedUserId', userId);
      formData.append('state', this.lineAssignment!.state!.toString());
      formData.append('comment', this.lineAssignment!.comment!);
      formData.append('isGeneralComment', this.lineAssignment!.isGeneralComment!.toString());
      formData.append('createdBy', this.lineAssignment!.createdBy!);
      formData.append('modifiedBy', this.lineAssignment!.modifiedBy!);
      for (let attachment of this.lineAssignment!.attachments!) {
        formData.append('attachments', attachment);
      }
      this.requestLineService.UpdateLineAssignment(formData).subscribe(response => {
        let changeType: string = this.newActionInputParam.changeType ? this.newActionInputParam.changeType.replace(/ /g, "_") : '';
        this.router.navigate([
          `../${requestConst.approvalDetail}/${this.newActionInputParam.requestId}/${this.newActionInputParam.requestNumber}/${this.newActionInputParam.requestIssueId}/${this.newActionInputParam.actionNumber}/${changeType}`],
          {
            state:
            {
              requestId: this.newActionInputParam.requestId,
              requestNumber: this.newActionInputParam.requestNumber,
              requestIssueId: this.newActionInputParam.requestIssueId,
              actionNumber: this.newActionInputParam.actionNumber,
              changeType: this.newActionInputParam.changeType,
            },
            relativeTo: this.route
          });
      })
    }
  }

  cancel() {
    this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
      this.navViewSAR();
    })
  }

  addTempActionRequest(item: any) {
    this.tempActionRequest = { ...item };
  }

  addLineAssignment(item: any) {
    this.lineAssignment = { ...item };
  }

  cancelAction(data: DeleteLineItemDto) {
    this.deleteLineItem = { ...data };
  }

  setDraftAvailability(isDraftavailable: any) {
    this.isDraftavailable = isDraftavailable;
  }

}