<div class="contentBox">
    <h1 class="content-top"><a class="c-left" routerLink="/setup"><span class="fa fa-fw fa-angle-left"></span></a>SAR User
    </h1>
    <div class="text-align-right mt-20">
        <button kendoButton class="border-button ml-20" (click)="import()">Import</button>
        <button kendoButton class="border-button ml-20" (click)="exportToExcel(grid)"> Export </button>
        <button kendoButton class="border-button ml-20" (click)="manage(true,false,'',0, '', '', '', '', '', 0, [],[])"> Create New SAR User
        </button>
    </div>
    <div class="search mt-20">

        <kendo-textbox [style.width.px]="400" (keyup.enter)="updateGrid()" [(ngModel)]="searchText"
            placeholder="Search by Name and Email">
        </kendo-textbox>

        <button kendoButton class="primary-button ml-20" (click)="updateGrid()"> Search </button>
        <button kendoButton class="border-button ml-20" (click)="cancelSearch()"> Reset </button>
    </div>
    <div class="d-flex a-center mt-20">
        <label class="lable">Show Inactive Group</label>
        <label class="switch ml-20">
            <input type="checkbox" #showInactiveCheck id="showInactiveCheck" [checked]="showInactive"
                (change)="activeChanged(showInactiveCheck.checked)">
            <span class="slider round"></span>
        </label>
    </div>

    <kendo-grid class="mt-20" [kendoGridBinding]="gridData" #grid="kendoGrid" [pageSize]="10" [resizable]="true" [pageable]="true">
         <kendo-grid-excel [fetchData]="commonService.exportGridData(this.gridData, '', 'asc')"  fileName="SARUser.xlsx">
            <!-- <kendo-excelexport-column field="cai" title="CAI"></kendo-excelexport-column> /* Commentted for 7136137: CAI Deprecation */ -->
            <kendo-excelexport-column field="name" title="Name"></kendo-excelexport-column>
            <kendo-excelexport-column field="email" title="Email"></kendo-excelexport-column>
            <kendo-excelexport-column field="businessUnitname" title="Business Unit"></kendo-excelexport-column>
            <kendo-excelexport-column field="rolesArr" title="Access Group Roles"></kendo-excelexport-column>
            <kendo-excelexport-column field="groupsArr" title="Group Names"></kendo-excelexport-column>
            <kendo-excelexport-column field="objectId" title="ObjectId" [hidden]="false"></kendo-excelexport-column>
            <kendo-excelexport-column field="isActive" title="Is Active"></kendo-excelexport-column>
            <kendo-excelexport-column field="createdBy" title="Created By"></kendo-excelexport-column>
            <kendo-excelexport-column field="modifiedBy" title="Modified By"></kendo-excelexport-column>
        </kendo-grid-excel>
        <!-- <kendo-grid-column field="cai" title="CAI" [width]="40"> /* Commentted for 7136137: CAI Deprecation */
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="javascript:void();" 
                    (click)="manage(false,dataItem.isActive,dataItem.name,dataItem.id, dataItem.cai, dataItem.email, dataItem.provisionId, dataItem.externalId, dataItem.businessUnitname, dataItem.businessUnitId, dataItem.roleDtos, dataItem.groupDtos )">{{dataItem.cai}}
                </a>
            </ng-template>
        </kendo-grid-column> /* Commentted for 7136137: CAI Deprecation */ -->
        
        <kendo-grid-column field="name" title="Name" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="javascript:void();" 
                    (click)="manage(false, dataItem.isActive, dataItem.name, dataItem.id, dataItem.objectId, dataItem.email, dataItem.provisionId, dataItem.externalId, dataItem.businessUnitname, dataItem.businessUnitId, dataItem.roleDtos, dataItem.groupDtos )">{{dataItem.name}}
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="email" title="Email" kendoTooltip="dataItem.email" [width]="50"></kendo-grid-column>
        <kendo-grid-column field="businessUnitname" title="Business Unit" [width]="60"></kendo-grid-column>
        
        <kendo-grid-column field="roleDtos" title="Access Group Roles" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div>{{getRoleAndGroupNames(dataItem.roleDtos, 'rolesArr', dataItem)}} </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="groupDtos" title="Group Names" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div>{{getRoleAndGroupNames(dataItem.groupDtos, 'groupsArr', dataItem)}} </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="objectId" title="objectId" [width]="25" [hidden]="false"></kendo-grid-column>
        <kendo-grid-column field="isActive" title="Is Active" [width]="25">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.isActive">
                    <span class="yesValue"> Yes</span>
                </span>
                <span *ngIf=" !dataItem.isActive">
                    <span class="noValue"> No</span>
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>