<div class="sar-contentBox">
  <h2 class="heading content-top">All SARs</h2>
  <section class="order-component">
    <ng-template #template let-anchor>
      <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div class="grid-box" kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
      (mouseover)="showTooltip($event)">
      <kendo-grid [data]="gridView" [loading]="loadingPanelVisible" [resizable]="true" [filterable]="true"
        [groupable]="true" [group]="groups" (groupChange)="groupChange($event)" [pageSize]="pageSize" [skip]="skip"
        [filter]="filters" [sortable]="true" [pageable]="{
    buttonCount: buttonCount,
    info: info,
    pageSizes: pageSizes,
    previousNext: previousNext }" [sortable]="{
    allowUnsort: allowUnsort,
    mode: multiple ? 'multiple' : 'single'
    }" [sort]="sort" (dataStateChange)="dataStateChange($event)" class="kendo-grid">

        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button type="button" class="kendo-button"
            [ngClass]="{'excelData' : totalRecords > 0 , 'noExcelData': totalRecords ==0}" kendoGridExcelCommand
            icon="file-excel">
            Export to Excel
          </button>
        </ng-template>
        <kendo-grid-column [width]="150" field="sarId" title="SAR ID">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="javascript:void();" (click)="manage(dataItem.id)">{{dataItem.sarId}}</a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="businessUnit" title="Business Unit">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="title" title="Title">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="actionNumber" [filterable]="false" title="Action Number ">
          <!-- number -->
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="javascript:void();" (click)="navViewAction(dataItem)">{{dataItem.actionNumber}}</a>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="action" title="Action ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="priority" title="Priority ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="owner" title="Owner ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="createdOn" title="Created On " format="{0:dd/MM/yyyy}">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="description" title="Description ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="manufacturer" title="Manufacturer ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="manufacturerPartNumber" title="Manufacturer Part Number ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="uom" title="UOM ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="segment" title="Segment ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="mrp" title="MRP ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="lineNumber" [filterable]="false" title="line Number ">
          <!-- number -->
        </kendo-grid-column>
        <kendo-grid-column [width]="150"  field="materialNumber" title="Material Number ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="materialGroupCode" title="Material Group Code ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="shortDescription" title="Short Description ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="enrichedDescription" title="Enriched Description ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="dataChangeType" title="Data Change Type ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="newData" title="New Data ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="plant" title="Plant ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="plantDF" title="Plant DF ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="storageLocation" title="Storage Location ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="storageLocationDF" title="Storage Location DF ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="recommendedMin" title="Recommended Min " [filterable]="false">
          <!-- Number -->
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="recommendedMax" title="Recommended Max " [filterable]="false">
          <!-- Number -->
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="valuationType" title="Valuation Type ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="valuationTypeDF" title="Valuation Type DF ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="bin" title="Bin ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="status" title="Status ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="submittedBy" title="Submitted By ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" filter="date" field="submittedOn" title="Submitted On ">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="assignedTo" title="Assigned To ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="assignedGroup" title="Assigned Group ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="approvedBy" title="Approved By ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="approvedOn" title="Approved On " format="{0:dd/MM/yyyy}">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="closedBy" title="Closed By ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="closedOn" title="Closed On " format="{0:dd/MM/yyyy}">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-cell>
          </ng-template>
        </kendo-grid-column>



        <kendo-grid-excel fileName="AllRequests.xlsx" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>


    </div>

  </section>
</div>
