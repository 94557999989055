export const requestCategoryEnum = {
  createdByMe: 1,
  assignedToMe: 2,
  assignedToMyGroup: 3,
  all: 10
};

export const requestStateEnum = {
  all: 0,
  rejected: 1,
  completed: 2,
  pendingMMD: 3,
  pendingStockingApproval: 4,
  draft: 5,
  cancelled: 6
};

export const requestIssueTypeEnum = {
  addNewItem: 3,
  changeData: 2,
  extensionMRPUpdate: 1,
  all: 0
};

export const sarSelectTypeEnum = {
  mySar: -2,
  myBu: -1
};

export const actionTypeConst =
{
  extentionMRPUpdate: 'Extension MRP Update',
  changeData: 'Change Data',
  addNewItem: 'Add New Item'
};
